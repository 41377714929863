import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import "tailwindcss/tailwind.css";
import eventBus from '@/utils/eventBus';
import { websocketService } from '@/utils/websocket'; // 引入 WebSocket 服务
import {protobuf} from "@/utils/bundle"
import tool from "@/utils/tool";

console.log(6666,protobuf);

import header from './components/header.vue'; // 导入组件

const app = createApp(App)

// 将 WebSocket 服务挂载到全局
app.config.globalProperties.$ws = websocketService;
app.config.globalProperties.$eventBus = eventBus;
app.config.globalProperties.$tool = tool;
app.config.globalProperties.$buff = protobuf;

app.use(router)
app.mount('#app')
app.component('headertop',header);