<template>
	<router-view />
</template>

<script>
	let socketInterval = null;
	export default {
		data() {
			return {
				websock: null,
				token:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOiJwY191c2VyOjEzIiwicm5TdHIiOiJpVnJ3aUNJZGJJSDlMRDEyOWVpN1NuR3lOV0dxakk2NCIsInVzZXJJZCI6MTN9.fdS1PLdfHaw2_7HmVR3lpMDl1DDl7b6hOrAgKZgNgxI'
			}
		},
		mounted() {
			let query = this.getUrlQueyr();
			if(query.token){
				this.token = query.token;
			}
			this.initWebSocket();
			
		},
		destroyed() {
			//页面销毁时关闭webSocket
			this.$ws.close();
		},
		methods: {
			getUrlQueyr(){
				let params = {};
				let queryString = window.location.search;
				if (queryString) {
				  let searchParams = new URLSearchParams(queryString);
				  searchParams.forEach((value, key) => {
					params[key] = value;
				  });
				}
				return params;
			},
			initWebSocket() { //webSocket初始化
				let that = this;
				// let path = '';
				// //这里是关键，可以判断当前环境是生产环境还是开发环境，开发环境加测试域名即可调试，生产环境由于代码要放到服务器获取本地域名即可
				// if (process.env.NODE_ENV === 'development') {
				// 	path = '192.168.192.198:8090'; //这里改成你测试的域名
				// } else {
				// 	path = window.location.host;
				// }
				//如果是wss协议,这里path一定要域名才能正常连接 ip+端口的方式不行
				// const wsuri = 'ws://' + path + "/ws?t=ssc&token=" + this.token;
				const wsuri = 'ws://192.168.192.198:8090?token=' + this.token;
				this.$ws.connect(wsuri);
			}
		}
	}
</script>
<style lang="scss">
	@import './css/base.scss';
	@import './css/components.scss';
	@import './css/utilities.scss';
	@import './css/history.scss';

	@tailwind components;
	@tailwind utilities;

	@font-face {
		font-family: 'Koulen';
		src: url("@/assets/font/Koulen.ttf") format('truetype');
		font-weight: normal;
		font-style: normal;
	}

	* {
		user-select: none;
		/* 禁止选中 */
		-webkit-user-select: none;
		/* 针对 Safari 浏览器 */
		-moz-user-select: none;
		/* 针对 Firefox 浏览器 */
		-ms-user-select: none;
		/* 针对 IE 浏览器 */
		cursor: default;
		/* 鼠标样式变为默认 */
	}

	body {
		background: theme('colors.bgColor4');
	}
	.bg-blue{
		background: #2CABE7 !important;
	}
	.bg-red{
		background: #E72CAB !important;
	}
	.bg-purple{
		background: #722FFF !important;
	}
	.bg-green{
		background: #BCFE2F !important;
		color: #000000 !important;
	}
</style>