// websocket.js
import {protobuf} from "@/utils/bundle"

class WebSocketService {
  constructor() {
    this.ws = null;
    this.url = ''; // 设置你的 WebSocket 服务器地址
    this.reconnectAttempts = 0; // 重连次数
    this.maxReconnectAttempts = 5; // 最大重连次数
    this.listeners = {}; // 事件监听器
  }

  connect(url) {
    this.url = url;
    this.ws = new WebSocket(url);
	this.ws.binaryType = 'arraybuffer';

    // 连接成功
    this.ws.onopen = () => {
      console.log('WebSocket connection opened');
      this.reconnectAttempts = 0; // 重置重连次数
    };

    // 接收到消息
    this.ws.onmessage = (event) => {
		let data;
		if(typeof(event.data) == 'string'){
			data = JSON.parse(event.data);
			this.dispatchEvent(data.msgType, data);
		}else{
			data = protobuf.response.CommonResponse.decode(new Uint8Array(event.data));
			this.dispatchEvent((100+data.type), data);
		}
    };

    // 连接关闭
    this.ws.onclose = () => {
      console.log('WebSocket connection closed');
      this.reconnect();
    };

    // 连接出错
    this.ws.onerror = (error) => {
      console.error('WebSocket error', error);
    };
  }

  // 发送消息
  send(data) {
	console.log(WebSocket.OPEN);
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
	let sendData;
	if((data instanceof Uint8Array)){
		sendData = data
	}else{
		sendData = JSON.stringify(data);
	}
	this.ws.send(sendData);
    } else {
      console.error('WebSocket is not open');
    }
  }

  // 重连机制
  reconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      console.log(`Reconnecting attempt ${this.reconnectAttempts}`);
      setTimeout(() => {
        this.connect(this.url);
      }, 3000); // 等待 3 秒再重连
    } else {
      console.log('Max reconnect attempts reached');
    }
  }

  // 注册监听器
  on(type, listener) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(listener);
  }
  // 移除监听器
  offAll() {
     this.listeners = [];
  }
  // 移除监听器
  off(type, listener) {
    if (this.listeners[type]) {
      this.listeners[type] = this.listeners[type].filter(l => l !== listener);
    }
  }

  // 触发事件
  dispatchEvent(type, event) {
    if (this.listeners[type]) {
        console.log(`Dispatching event ${type} with data:`, event); // 输出调试信息
        this.listeners[type].forEach(listener => listener(event));
      } else {
        console.warn(`未监听到的type: ${type}`);
      }
  }
}

// 导出单例
export const websocketService = new WebSocketService();
