import {protobuf} from "@/utils/bundle"

let tool = {
	createSSCBetData: function (data) {
		console.log(data);
		let MessageType = data.key ? data.key : 'BUY';
		const msg = new protobuf.request.CommonRequest();
		msg.msgId = Math.floor(Math.random() * 9000000000) + 1000000000;;
		msg.type = protobuf.common.MessageType[data.key];
		
		const payload = new protobuf.request.BuySSC();
		payload.childGameType = protobuf.request.BuySSC.ChildGameType[data.gameType];
		payload.tickets = data.tickets;
		payload.D = data.D;
		payload.buy = data.buy;
		payload.universal = data.universal;
		
		msg.payload = protobuf.request.BuySSC.encode(payload).finish();
		
		return protobuf.request.CommonRequest.encode(msg).finish();
	}
}
export default tool;