/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const protobuf = $root.protobuf = (() => {

    /**
     * Namespace protobuf.
     * @exports protobuf
     * @namespace
     */
    const protobuf = {};

    protobuf.common = (function() {

        /**
         * Namespace common.
         * @memberof protobuf
         * @namespace
         */
        const common = {};

        /**
         * Lottery enum.
         * @name protobuf.common.Lottery
         * @enum {number}
         * @property {number} SSC=0 SSC value
         * @property {number} LHC=1 LHC value
         * @property {number} XZC=2 XZC value
         * @property {number} FC3D=3 FC3D value
         * @property {number} TEXAS=4 TEXAS value
         * @property {number} SLOTS=5 SLOTS value
         */
        common.Lottery = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SSC"] = 0;
            values[valuesById[1] = "LHC"] = 1;
            values[valuesById[2] = "XZC"] = 2;
            values[valuesById[3] = "FC3D"] = 3;
            values[valuesById[4] = "TEXAS"] = 4;
            values[valuesById[5] = "SLOTS"] = 5;
            return values;
        })();

        /**
         * MessageType enum.
         * @name protobuf.common.MessageType
         * @enum {number}
         * @property {number} CALCULATE=0 CALCULATE value
         * @property {number} BUY=1 BUY value
         * @property {number} HIS_LOTTO=2 HIS_LOTTO value
         * @property {number} HIS_ORDER=3 HIS_ORDER value
         * @property {number} BUY_AGAIN=4 BUY_AGAIN value
         */
        common.MessageType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CALCULATE"] = 0;
            values[valuesById[1] = "BUY"] = 1;
            values[valuesById[2] = "HIS_LOTTO"] = 2;
            values[valuesById[3] = "HIS_ORDER"] = 3;
            values[valuesById[4] = "BUY_AGAIN"] = 4;
            return values;
        })();

        return common;
    })();

    protobuf.request = (function() {

        /**
         * Namespace request.
         * @memberof protobuf
         * @namespace
         */
        const request = {};

        request.CommonRequest = (function() {

            /**
             * Properties of a CommonRequest.
             * @memberof protobuf.request
             * @interface ICommonRequest
             * @property {number|null} [msgId] CommonRequest msgId
             * @property {protobuf.common.MessageType|null} [type] CommonRequest type
             * @property {Uint8Array|null} [payload] CommonRequest payload
             */

            /**
             * Constructs a new CommonRequest.
             * @memberof protobuf.request
             * @classdesc Represents a CommonRequest.
             * @implements ICommonRequest
             * @constructor
             * @param {protobuf.request.ICommonRequest=} [properties] Properties to set
             */
            function CommonRequest(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CommonRequest msgId.
             * @member {number} msgId
             * @memberof protobuf.request.CommonRequest
             * @instance
             */
            CommonRequest.prototype.msgId = 0;

            /**
             * CommonRequest type.
             * @member {protobuf.common.MessageType} type
             * @memberof protobuf.request.CommonRequest
             * @instance
             */
            CommonRequest.prototype.type = 0;

            /**
             * CommonRequest payload.
             * @member {Uint8Array} payload
             * @memberof protobuf.request.CommonRequest
             * @instance
             */
            CommonRequest.prototype.payload = $util.newBuffer([]);

            /**
             * Creates a new CommonRequest instance using the specified properties.
             * @function create
             * @memberof protobuf.request.CommonRequest
             * @static
             * @param {protobuf.request.ICommonRequest=} [properties] Properties to set
             * @returns {protobuf.request.CommonRequest} CommonRequest instance
             */
            CommonRequest.create = function create(properties) {
                return new CommonRequest(properties);
            };

            /**
             * Encodes the specified CommonRequest message. Does not implicitly {@link protobuf.request.CommonRequest.verify|verify} messages.
             * @function encode
             * @memberof protobuf.request.CommonRequest
             * @static
             * @param {protobuf.request.ICommonRequest} message CommonRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CommonRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.msgId);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.payload);
                return writer;
            };

            /**
             * Encodes the specified CommonRequest message, length delimited. Does not implicitly {@link protobuf.request.CommonRequest.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.request.CommonRequest
             * @static
             * @param {protobuf.request.ICommonRequest} message CommonRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CommonRequest.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CommonRequest message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.request.CommonRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.request.CommonRequest} CommonRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CommonRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.request.CommonRequest();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.msgId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.type = reader.int32();
                            break;
                        }
                    case 3: {
                            message.payload = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CommonRequest message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.request.CommonRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.request.CommonRequest} CommonRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CommonRequest.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CommonRequest message.
             * @function verify
             * @memberof protobuf.request.CommonRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CommonRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (!$util.isInteger(message.msgId))
                        return "msgId: integer expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.payload != null && message.hasOwnProperty("payload"))
                    if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                        return "payload: buffer expected";
                return null;
            };

            /**
             * Creates a CommonRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.request.CommonRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.request.CommonRequest} CommonRequest
             */
            CommonRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.request.CommonRequest)
                    return object;
                let message = new $root.protobuf.request.CommonRequest();
                if (object.msgId != null)
                    message.msgId = object.msgId | 0;
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "CALCULATE":
                case 0:
                    message.type = 0;
                    break;
                case "BUY":
                case 1:
                    message.type = 1;
                    break;
                case "HIS_LOTTO":
                case 2:
                    message.type = 2;
                    break;
                case "HIS_ORDER":
                case 3:
                    message.type = 3;
                    break;
                case "BUY_AGAIN":
                case 4:
                    message.type = 4;
                    break;
                }
                if (object.payload != null)
                    if (typeof object.payload === "string")
                        $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
                    else if (object.payload.length >= 0)
                        message.payload = object.payload;
                return message;
            };

            /**
             * Creates a plain object from a CommonRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.request.CommonRequest
             * @static
             * @param {protobuf.request.CommonRequest} message CommonRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CommonRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.msgId = 0;
                    object.type = options.enums === String ? "CALCULATE" : 0;
                    if (options.bytes === String)
                        object.payload = "";
                    else {
                        object.payload = [];
                        if (options.bytes !== Array)
                            object.payload = $util.newBuffer(object.payload);
                    }
                }
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    object.msgId = message.msgId;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protobuf.common.MessageType[message.type] === undefined ? message.type : $root.protobuf.common.MessageType[message.type] : message.type;
                if (message.payload != null && message.hasOwnProperty("payload"))
                    object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
                return object;
            };

            /**
             * Converts this CommonRequest to JSON.
             * @function toJSON
             * @memberof protobuf.request.CommonRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CommonRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CommonRequest
             * @function getTypeUrl
             * @memberof protobuf.request.CommonRequest
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CommonRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.request.CommonRequest";
            };

            return CommonRequest;
        })();

        request.BuySSC = (function() {

            /**
             * Properties of a BuySSC.
             * @memberof protobuf.request
             * @interface IBuySSC
             * @property {protobuf.request.BuySSC.ChildGameType|null} [childGameType] BuySSC childGameType
             * @property {number|null} [D] BuySSC D
             * @property {boolean|null} [universal] BuySSC universal
             * @property {string|null} [buy] BuySSC buy
             * @property {number|null} [tickets] BuySSC tickets
             */

            /**
             * Constructs a new BuySSC.
             * @memberof protobuf.request
             * @classdesc Represents a BuySSC.
             * @implements IBuySSC
             * @constructor
             * @param {protobuf.request.IBuySSC=} [properties] Properties to set
             */
            function BuySSC(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BuySSC childGameType.
             * @member {protobuf.request.BuySSC.ChildGameType} childGameType
             * @memberof protobuf.request.BuySSC
             * @instance
             */
            BuySSC.prototype.childGameType = 0;

            /**
             * BuySSC D.
             * @member {number} D
             * @memberof protobuf.request.BuySSC
             * @instance
             */
            BuySSC.prototype.D = 0;

            /**
             * BuySSC universal.
             * @member {boolean} universal
             * @memberof protobuf.request.BuySSC
             * @instance
             */
            BuySSC.prototype.universal = false;

            /**
             * BuySSC buy.
             * @member {string} buy
             * @memberof protobuf.request.BuySSC
             * @instance
             */
            BuySSC.prototype.buy = "";

            /**
             * BuySSC tickets.
             * @member {number} tickets
             * @memberof protobuf.request.BuySSC
             * @instance
             */
            BuySSC.prototype.tickets = 0;

            /**
             * Creates a new BuySSC instance using the specified properties.
             * @function create
             * @memberof protobuf.request.BuySSC
             * @static
             * @param {protobuf.request.IBuySSC=} [properties] Properties to set
             * @returns {protobuf.request.BuySSC} BuySSC instance
             */
            BuySSC.create = function create(properties) {
                return new BuySSC(properties);
            };

            /**
             * Encodes the specified BuySSC message. Does not implicitly {@link protobuf.request.BuySSC.verify|verify} messages.
             * @function encode
             * @memberof protobuf.request.BuySSC
             * @static
             * @param {protobuf.request.IBuySSC} message BuySSC message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuySSC.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.childGameType != null && Object.hasOwnProperty.call(message, "childGameType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.childGameType);
                if (message.D != null && Object.hasOwnProperty.call(message, "D"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.D);
                if (message.universal != null && Object.hasOwnProperty.call(message, "universal"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.universal);
                if (message.buy != null && Object.hasOwnProperty.call(message, "buy"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.buy);
                if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.tickets);
                return writer;
            };

            /**
             * Encodes the specified BuySSC message, length delimited. Does not implicitly {@link protobuf.request.BuySSC.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.request.BuySSC
             * @static
             * @param {protobuf.request.IBuySSC} message BuySSC message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuySSC.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuySSC message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.request.BuySSC
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.request.BuySSC} BuySSC
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuySSC.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.request.BuySSC();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.childGameType = reader.int32();
                            break;
                        }
                    case 2: {
                            message.D = reader.int32();
                            break;
                        }
                    case 3: {
                            message.universal = reader.bool();
                            break;
                        }
                    case 4: {
                            message.buy = reader.string();
                            break;
                        }
                    case 5: {
                            message.tickets = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuySSC message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.request.BuySSC
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.request.BuySSC} BuySSC
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuySSC.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuySSC message.
             * @function verify
             * @memberof protobuf.request.BuySSC
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuySSC.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    switch (message.childGameType) {
                    default:
                        return "childGameType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.D != null && message.hasOwnProperty("D"))
                    if (!$util.isInteger(message.D))
                        return "D: integer expected";
                if (message.universal != null && message.hasOwnProperty("universal"))
                    if (typeof message.universal !== "boolean")
                        return "universal: boolean expected";
                if (message.buy != null && message.hasOwnProperty("buy"))
                    if (!$util.isString(message.buy))
                        return "buy: string expected";
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    if (!$util.isInteger(message.tickets))
                        return "tickets: integer expected";
                return null;
            };

            /**
             * Creates a BuySSC message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.request.BuySSC
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.request.BuySSC} BuySSC
             */
            BuySSC.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.request.BuySSC)
                    return object;
                let message = new $root.protobuf.request.BuySSC();
                switch (object.childGameType) {
                default:
                    if (typeof object.childGameType === "number") {
                        message.childGameType = object.childGameType;
                        break;
                    }
                    break;
                case "SMALL_BIG":
                case 0:
                    message.childGameType = 0;
                    break;
                case "ODD_EVEN":
                case 1:
                    message.childGameType = 1;
                    break;
                case "SUM":
                case 2:
                    message.childGameType = 2;
                    break;
                case "STARS":
                case 3:
                    message.childGameType = 3;
                    break;
                case "PACKING":
                case 4:
                    message.childGameType = 4;
                    break;
                }
                if (object.D != null)
                    message.D = object.D | 0;
                if (object.universal != null)
                    message.universal = Boolean(object.universal);
                if (object.buy != null)
                    message.buy = String(object.buy);
                if (object.tickets != null)
                    message.tickets = object.tickets | 0;
                return message;
            };

            /**
             * Creates a plain object from a BuySSC message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.request.BuySSC
             * @static
             * @param {protobuf.request.BuySSC} message BuySSC
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuySSC.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.childGameType = options.enums === String ? "SMALL_BIG" : 0;
                    object.D = 0;
                    object.universal = false;
                    object.buy = "";
                    object.tickets = 0;
                }
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    object.childGameType = options.enums === String ? $root.protobuf.request.BuySSC.ChildGameType[message.childGameType] === undefined ? message.childGameType : $root.protobuf.request.BuySSC.ChildGameType[message.childGameType] : message.childGameType;
                if (message.D != null && message.hasOwnProperty("D"))
                    object.D = message.D;
                if (message.universal != null && message.hasOwnProperty("universal"))
                    object.universal = message.universal;
                if (message.buy != null && message.hasOwnProperty("buy"))
                    object.buy = message.buy;
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    object.tickets = message.tickets;
                return object;
            };

            /**
             * Converts this BuySSC to JSON.
             * @function toJSON
             * @memberof protobuf.request.BuySSC
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuySSC.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BuySSC
             * @function getTypeUrl
             * @memberof protobuf.request.BuySSC
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BuySSC.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.request.BuySSC";
            };

            /**
             * ChildGameType enum.
             * @name protobuf.request.BuySSC.ChildGameType
             * @enum {number}
             * @property {number} SMALL_BIG=0 SMALL_BIG value
             * @property {number} ODD_EVEN=1 ODD_EVEN value
             * @property {number} SUM=2 SUM value
             * @property {number} STARS=3 STARS value
             * @property {number} PACKING=4 PACKING value
             */
            BuySSC.ChildGameType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SMALL_BIG"] = 0;
                values[valuesById[1] = "ODD_EVEN"] = 1;
                values[valuesById[2] = "SUM"] = 2;
                values[valuesById[3] = "STARS"] = 3;
                values[valuesById[4] = "PACKING"] = 4;
                return values;
            })();

            return BuySSC;
        })();

        request.BuyLHC = (function() {

            /**
             * Properties of a BuyLHC.
             * @memberof protobuf.request
             * @interface IBuyLHC
             * @property {protobuf.request.BuyLHC.ChildGameType|null} [childGameType] BuyLHC childGameType
             * @property {string|null} [buy] BuyLHC buy
             * @property {number|null} [tickets] BuyLHC tickets
             */

            /**
             * Constructs a new BuyLHC.
             * @memberof protobuf.request
             * @classdesc Represents a BuyLHC.
             * @implements IBuyLHC
             * @constructor
             * @param {protobuf.request.IBuyLHC=} [properties] Properties to set
             */
            function BuyLHC(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BuyLHC childGameType.
             * @member {protobuf.request.BuyLHC.ChildGameType} childGameType
             * @memberof protobuf.request.BuyLHC
             * @instance
             */
            BuyLHC.prototype.childGameType = 0;

            /**
             * BuyLHC buy.
             * @member {string} buy
             * @memberof protobuf.request.BuyLHC
             * @instance
             */
            BuyLHC.prototype.buy = "";

            /**
             * BuyLHC tickets.
             * @member {number} tickets
             * @memberof protobuf.request.BuyLHC
             * @instance
             */
            BuyLHC.prototype.tickets = 0;

            /**
             * Creates a new BuyLHC instance using the specified properties.
             * @function create
             * @memberof protobuf.request.BuyLHC
             * @static
             * @param {protobuf.request.IBuyLHC=} [properties] Properties to set
             * @returns {protobuf.request.BuyLHC} BuyLHC instance
             */
            BuyLHC.create = function create(properties) {
                return new BuyLHC(properties);
            };

            /**
             * Encodes the specified BuyLHC message. Does not implicitly {@link protobuf.request.BuyLHC.verify|verify} messages.
             * @function encode
             * @memberof protobuf.request.BuyLHC
             * @static
             * @param {protobuf.request.IBuyLHC} message BuyLHC message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyLHC.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.childGameType != null && Object.hasOwnProperty.call(message, "childGameType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.childGameType);
                if (message.buy != null && Object.hasOwnProperty.call(message, "buy"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.buy);
                if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.tickets);
                return writer;
            };

            /**
             * Encodes the specified BuyLHC message, length delimited. Does not implicitly {@link protobuf.request.BuyLHC.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.request.BuyLHC
             * @static
             * @param {protobuf.request.IBuyLHC} message BuyLHC message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyLHC.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuyLHC message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.request.BuyLHC
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.request.BuyLHC} BuyLHC
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyLHC.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.request.BuyLHC();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.childGameType = reader.int32();
                            break;
                        }
                    case 3: {
                            message.buy = reader.string();
                            break;
                        }
                    case 4: {
                            message.tickets = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuyLHC message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.request.BuyLHC
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.request.BuyLHC} BuyLHC
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyLHC.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuyLHC message.
             * @function verify
             * @memberof protobuf.request.BuyLHC
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuyLHC.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    switch (message.childGameType) {
                    default:
                        return "childGameType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.buy != null && message.hasOwnProperty("buy"))
                    if (!$util.isString(message.buy))
                        return "buy: string expected";
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    if (!$util.isInteger(message.tickets))
                        return "tickets: integer expected";
                return null;
            };

            /**
             * Creates a BuyLHC message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.request.BuyLHC
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.request.BuyLHC} BuyLHC
             */
            BuyLHC.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.request.BuyLHC)
                    return object;
                let message = new $root.protobuf.request.BuyLHC();
                switch (object.childGameType) {
                default:
                    if (typeof object.childGameType === "number") {
                        message.childGameType = object.childGameType;
                        break;
                    }
                    break;
                case "MARK":
                case 0:
                    message.childGameType = 0;
                    break;
                case "FIT_3":
                case 1:
                    message.childGameType = 1;
                    break;
                case "FIT_6":
                case 2:
                    message.childGameType = 2;
                    break;
                case "DAY_NIGHT":
                case 3:
                    message.childGameType = 3;
                    break;
                case "SEASON":
                case 4:
                    message.childGameType = 4;
                    break;
                }
                if (object.buy != null)
                    message.buy = String(object.buy);
                if (object.tickets != null)
                    message.tickets = object.tickets | 0;
                return message;
            };

            /**
             * Creates a plain object from a BuyLHC message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.request.BuyLHC
             * @static
             * @param {protobuf.request.BuyLHC} message BuyLHC
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuyLHC.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.childGameType = options.enums === String ? "MARK" : 0;
                    object.buy = "";
                    object.tickets = 0;
                }
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    object.childGameType = options.enums === String ? $root.protobuf.request.BuyLHC.ChildGameType[message.childGameType] === undefined ? message.childGameType : $root.protobuf.request.BuyLHC.ChildGameType[message.childGameType] : message.childGameType;
                if (message.buy != null && message.hasOwnProperty("buy"))
                    object.buy = message.buy;
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    object.tickets = message.tickets;
                return object;
            };

            /**
             * Converts this BuyLHC to JSON.
             * @function toJSON
             * @memberof protobuf.request.BuyLHC
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuyLHC.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BuyLHC
             * @function getTypeUrl
             * @memberof protobuf.request.BuyLHC
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BuyLHC.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.request.BuyLHC";
            };

            /**
             * ChildGameType enum.
             * @name protobuf.request.BuyLHC.ChildGameType
             * @enum {number}
             * @property {number} MARK=0 MARK value
             * @property {number} FIT_3=1 FIT_3 value
             * @property {number} FIT_6=2 FIT_6 value
             * @property {number} DAY_NIGHT=3 DAY_NIGHT value
             * @property {number} SEASON=4 SEASON value
             */
            BuyLHC.ChildGameType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "MARK"] = 0;
                values[valuesById[1] = "FIT_3"] = 1;
                values[valuesById[2] = "FIT_6"] = 2;
                values[valuesById[3] = "DAY_NIGHT"] = 3;
                values[valuesById[4] = "SEASON"] = 4;
                return values;
            })();

            return BuyLHC;
        })();

        request.BuyFC3D = (function() {

            /**
             * Properties of a BuyFC3D.
             * @memberof protobuf.request
             * @interface IBuyFC3D
             * @property {protobuf.request.BuyFC3D.ChildGameType|null} [childGameType] BuyFC3D childGameType
             * @property {number|null} [D] BuyFC3D D
             * @property {boolean|null} [universal] BuyFC3D universal
             * @property {string|null} [buy] BuyFC3D buy
             * @property {number|null} [tickets] BuyFC3D tickets
             */

            /**
             * Constructs a new BuyFC3D.
             * @memberof protobuf.request
             * @classdesc Represents a BuyFC3D.
             * @implements IBuyFC3D
             * @constructor
             * @param {protobuf.request.IBuyFC3D=} [properties] Properties to set
             */
            function BuyFC3D(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BuyFC3D childGameType.
             * @member {protobuf.request.BuyFC3D.ChildGameType} childGameType
             * @memberof protobuf.request.BuyFC3D
             * @instance
             */
            BuyFC3D.prototype.childGameType = 0;

            /**
             * BuyFC3D D.
             * @member {number} D
             * @memberof protobuf.request.BuyFC3D
             * @instance
             */
            BuyFC3D.prototype.D = 0;

            /**
             * BuyFC3D universal.
             * @member {boolean} universal
             * @memberof protobuf.request.BuyFC3D
             * @instance
             */
            BuyFC3D.prototype.universal = false;

            /**
             * BuyFC3D buy.
             * @member {string} buy
             * @memberof protobuf.request.BuyFC3D
             * @instance
             */
            BuyFC3D.prototype.buy = "";

            /**
             * BuyFC3D tickets.
             * @member {number} tickets
             * @memberof protobuf.request.BuyFC3D
             * @instance
             */
            BuyFC3D.prototype.tickets = 0;

            /**
             * Creates a new BuyFC3D instance using the specified properties.
             * @function create
             * @memberof protobuf.request.BuyFC3D
             * @static
             * @param {protobuf.request.IBuyFC3D=} [properties] Properties to set
             * @returns {protobuf.request.BuyFC3D} BuyFC3D instance
             */
            BuyFC3D.create = function create(properties) {
                return new BuyFC3D(properties);
            };

            /**
             * Encodes the specified BuyFC3D message. Does not implicitly {@link protobuf.request.BuyFC3D.verify|verify} messages.
             * @function encode
             * @memberof protobuf.request.BuyFC3D
             * @static
             * @param {protobuf.request.IBuyFC3D} message BuyFC3D message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyFC3D.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.childGameType != null && Object.hasOwnProperty.call(message, "childGameType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.childGameType);
                if (message.D != null && Object.hasOwnProperty.call(message, "D"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.D);
                if (message.universal != null && Object.hasOwnProperty.call(message, "universal"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.universal);
                if (message.buy != null && Object.hasOwnProperty.call(message, "buy"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.buy);
                if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.tickets);
                return writer;
            };

            /**
             * Encodes the specified BuyFC3D message, length delimited. Does not implicitly {@link protobuf.request.BuyFC3D.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.request.BuyFC3D
             * @static
             * @param {protobuf.request.IBuyFC3D} message BuyFC3D message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyFC3D.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuyFC3D message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.request.BuyFC3D
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.request.BuyFC3D} BuyFC3D
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyFC3D.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.request.BuyFC3D();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.childGameType = reader.int32();
                            break;
                        }
                    case 2: {
                            message.D = reader.int32();
                            break;
                        }
                    case 3: {
                            message.universal = reader.bool();
                            break;
                        }
                    case 4: {
                            message.buy = reader.string();
                            break;
                        }
                    case 5: {
                            message.tickets = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuyFC3D message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.request.BuyFC3D
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.request.BuyFC3D} BuyFC3D
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyFC3D.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuyFC3D message.
             * @function verify
             * @memberof protobuf.request.BuyFC3D
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuyFC3D.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    switch (message.childGameType) {
                    default:
                        return "childGameType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.D != null && message.hasOwnProperty("D"))
                    if (!$util.isInteger(message.D))
                        return "D: integer expected";
                if (message.universal != null && message.hasOwnProperty("universal"))
                    if (typeof message.universal !== "boolean")
                        return "universal: boolean expected";
                if (message.buy != null && message.hasOwnProperty("buy"))
                    if (!$util.isString(message.buy))
                        return "buy: string expected";
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    if (!$util.isInteger(message.tickets))
                        return "tickets: integer expected";
                return null;
            };

            /**
             * Creates a BuyFC3D message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.request.BuyFC3D
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.request.BuyFC3D} BuyFC3D
             */
            BuyFC3D.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.request.BuyFC3D)
                    return object;
                let message = new $root.protobuf.request.BuyFC3D();
                switch (object.childGameType) {
                default:
                    if (typeof object.childGameType === "number") {
                        message.childGameType = object.childGameType;
                        break;
                    }
                    break;
                case "NUMBERS":
                case 0:
                    message.childGameType = 0;
                    break;
                case "PICKS":
                case 1:
                    message.childGameType = 1;
                    break;
                case "SMALL_BIG":
                case 2:
                    message.childGameType = 2;
                    break;
                case "ODD_EVEN":
                case 3:
                    message.childGameType = 3;
                    break;
                case "SUM":
                case 4:
                    message.childGameType = 4;
                    break;
                }
                if (object.D != null)
                    message.D = object.D | 0;
                if (object.universal != null)
                    message.universal = Boolean(object.universal);
                if (object.buy != null)
                    message.buy = String(object.buy);
                if (object.tickets != null)
                    message.tickets = object.tickets | 0;
                return message;
            };

            /**
             * Creates a plain object from a BuyFC3D message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.request.BuyFC3D
             * @static
             * @param {protobuf.request.BuyFC3D} message BuyFC3D
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuyFC3D.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.childGameType = options.enums === String ? "NUMBERS" : 0;
                    object.D = 0;
                    object.universal = false;
                    object.buy = "";
                    object.tickets = 0;
                }
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    object.childGameType = options.enums === String ? $root.protobuf.request.BuyFC3D.ChildGameType[message.childGameType] === undefined ? message.childGameType : $root.protobuf.request.BuyFC3D.ChildGameType[message.childGameType] : message.childGameType;
                if (message.D != null && message.hasOwnProperty("D"))
                    object.D = message.D;
                if (message.universal != null && message.hasOwnProperty("universal"))
                    object.universal = message.universal;
                if (message.buy != null && message.hasOwnProperty("buy"))
                    object.buy = message.buy;
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    object.tickets = message.tickets;
                return object;
            };

            /**
             * Converts this BuyFC3D to JSON.
             * @function toJSON
             * @memberof protobuf.request.BuyFC3D
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuyFC3D.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BuyFC3D
             * @function getTypeUrl
             * @memberof protobuf.request.BuyFC3D
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BuyFC3D.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.request.BuyFC3D";
            };

            /**
             * ChildGameType enum.
             * @name protobuf.request.BuyFC3D.ChildGameType
             * @enum {number}
             * @property {number} NUMBERS=0 NUMBERS value
             * @property {number} PICKS=1 PICKS value
             * @property {number} SMALL_BIG=2 SMALL_BIG value
             * @property {number} ODD_EVEN=3 ODD_EVEN value
             * @property {number} SUM=4 SUM value
             */
            BuyFC3D.ChildGameType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NUMBERS"] = 0;
                values[valuesById[1] = "PICKS"] = 1;
                values[valuesById[2] = "SMALL_BIG"] = 2;
                values[valuesById[3] = "ODD_EVEN"] = 3;
                values[valuesById[4] = "SUM"] = 4;
                return values;
            })();

            return BuyFC3D;
        })();

        request.BuyTexas = (function() {

            /**
             * Properties of a BuyTexas.
             * @memberof protobuf.request
             * @interface IBuyTexas
             * @property {string|null} [buy] BuyTexas buy
             * @property {number|null} [tickets] BuyTexas tickets
             */

            /**
             * Constructs a new BuyTexas.
             * @memberof protobuf.request
             * @classdesc Represents a BuyTexas.
             * @implements IBuyTexas
             * @constructor
             * @param {protobuf.request.IBuyTexas=} [properties] Properties to set
             */
            function BuyTexas(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BuyTexas buy.
             * @member {string} buy
             * @memberof protobuf.request.BuyTexas
             * @instance
             */
            BuyTexas.prototype.buy = "";

            /**
             * BuyTexas tickets.
             * @member {number} tickets
             * @memberof protobuf.request.BuyTexas
             * @instance
             */
            BuyTexas.prototype.tickets = 0;

            /**
             * Creates a new BuyTexas instance using the specified properties.
             * @function create
             * @memberof protobuf.request.BuyTexas
             * @static
             * @param {protobuf.request.IBuyTexas=} [properties] Properties to set
             * @returns {protobuf.request.BuyTexas} BuyTexas instance
             */
            BuyTexas.create = function create(properties) {
                return new BuyTexas(properties);
            };

            /**
             * Encodes the specified BuyTexas message. Does not implicitly {@link protobuf.request.BuyTexas.verify|verify} messages.
             * @function encode
             * @memberof protobuf.request.BuyTexas
             * @static
             * @param {protobuf.request.IBuyTexas} message BuyTexas message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyTexas.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.buy != null && Object.hasOwnProperty.call(message, "buy"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.buy);
                if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.tickets);
                return writer;
            };

            /**
             * Encodes the specified BuyTexas message, length delimited. Does not implicitly {@link protobuf.request.BuyTexas.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.request.BuyTexas
             * @static
             * @param {protobuf.request.IBuyTexas} message BuyTexas message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyTexas.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuyTexas message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.request.BuyTexas
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.request.BuyTexas} BuyTexas
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyTexas.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.request.BuyTexas();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.buy = reader.string();
                            break;
                        }
                    case 2: {
                            message.tickets = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuyTexas message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.request.BuyTexas
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.request.BuyTexas} BuyTexas
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyTexas.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuyTexas message.
             * @function verify
             * @memberof protobuf.request.BuyTexas
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuyTexas.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.buy != null && message.hasOwnProperty("buy"))
                    if (!$util.isString(message.buy))
                        return "buy: string expected";
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    if (!$util.isInteger(message.tickets))
                        return "tickets: integer expected";
                return null;
            };

            /**
             * Creates a BuyTexas message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.request.BuyTexas
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.request.BuyTexas} BuyTexas
             */
            BuyTexas.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.request.BuyTexas)
                    return object;
                let message = new $root.protobuf.request.BuyTexas();
                if (object.buy != null)
                    message.buy = String(object.buy);
                if (object.tickets != null)
                    message.tickets = object.tickets | 0;
                return message;
            };

            /**
             * Creates a plain object from a BuyTexas message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.request.BuyTexas
             * @static
             * @param {protobuf.request.BuyTexas} message BuyTexas
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuyTexas.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.buy = "";
                    object.tickets = 0;
                }
                if (message.buy != null && message.hasOwnProperty("buy"))
                    object.buy = message.buy;
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    object.tickets = message.tickets;
                return object;
            };

            /**
             * Converts this BuyTexas to JSON.
             * @function toJSON
             * @memberof protobuf.request.BuyTexas
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuyTexas.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BuyTexas
             * @function getTypeUrl
             * @memberof protobuf.request.BuyTexas
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BuyTexas.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.request.BuyTexas";
            };

            return BuyTexas;
        })();

        request.BuySlots = (function() {

            /**
             * Properties of a BuySlots.
             * @memberof protobuf.request
             * @interface IBuySlots
             * @property {protobuf.request.BuySlots.ChildGameType|null} [childGameType] BuySlots childGameType
             * @property {string|null} [buy] BuySlots buy
             * @property {number|null} [D] BuySlots D
             * @property {number|null} [tickets] BuySlots tickets
             */

            /**
             * Constructs a new BuySlots.
             * @memberof protobuf.request
             * @classdesc Represents a BuySlots.
             * @implements IBuySlots
             * @constructor
             * @param {protobuf.request.IBuySlots=} [properties] Properties to set
             */
            function BuySlots(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BuySlots childGameType.
             * @member {protobuf.request.BuySlots.ChildGameType} childGameType
             * @memberof protobuf.request.BuySlots
             * @instance
             */
            BuySlots.prototype.childGameType = 0;

            /**
             * BuySlots buy.
             * @member {string} buy
             * @memberof protobuf.request.BuySlots
             * @instance
             */
            BuySlots.prototype.buy = "";

            /**
             * BuySlots D.
             * @member {number} D
             * @memberof protobuf.request.BuySlots
             * @instance
             */
            BuySlots.prototype.D = 0;

            /**
             * BuySlots tickets.
             * @member {number} tickets
             * @memberof protobuf.request.BuySlots
             * @instance
             */
            BuySlots.prototype.tickets = 0;

            /**
             * Creates a new BuySlots instance using the specified properties.
             * @function create
             * @memberof protobuf.request.BuySlots
             * @static
             * @param {protobuf.request.IBuySlots=} [properties] Properties to set
             * @returns {protobuf.request.BuySlots} BuySlots instance
             */
            BuySlots.create = function create(properties) {
                return new BuySlots(properties);
            };

            /**
             * Encodes the specified BuySlots message. Does not implicitly {@link protobuf.request.BuySlots.verify|verify} messages.
             * @function encode
             * @memberof protobuf.request.BuySlots
             * @static
             * @param {protobuf.request.IBuySlots} message BuySlots message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuySlots.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.childGameType != null && Object.hasOwnProperty.call(message, "childGameType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.childGameType);
                if (message.buy != null && Object.hasOwnProperty.call(message, "buy"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.buy);
                if (message.D != null && Object.hasOwnProperty.call(message, "D"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.D);
                if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.tickets);
                return writer;
            };

            /**
             * Encodes the specified BuySlots message, length delimited. Does not implicitly {@link protobuf.request.BuySlots.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.request.BuySlots
             * @static
             * @param {protobuf.request.IBuySlots} message BuySlots message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuySlots.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuySlots message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.request.BuySlots
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.request.BuySlots} BuySlots
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuySlots.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.request.BuySlots();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.childGameType = reader.int32();
                            break;
                        }
                    case 2: {
                            message.buy = reader.string();
                            break;
                        }
                    case 3: {
                            message.D = reader.int32();
                            break;
                        }
                    case 4: {
                            message.tickets = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuySlots message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.request.BuySlots
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.request.BuySlots} BuySlots
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuySlots.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuySlots message.
             * @function verify
             * @memberof protobuf.request.BuySlots
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuySlots.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    switch (message.childGameType) {
                    default:
                        return "childGameType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.buy != null && message.hasOwnProperty("buy"))
                    if (!$util.isString(message.buy))
                        return "buy: string expected";
                if (message.D != null && message.hasOwnProperty("D"))
                    if (!$util.isInteger(message.D))
                        return "D: integer expected";
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    if (!$util.isInteger(message.tickets))
                        return "tickets: integer expected";
                return null;
            };

            /**
             * Creates a BuySlots message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.request.BuySlots
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.request.BuySlots} BuySlots
             */
            BuySlots.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.request.BuySlots)
                    return object;
                let message = new $root.protobuf.request.BuySlots();
                switch (object.childGameType) {
                default:
                    if (typeof object.childGameType === "number") {
                        message.childGameType = object.childGameType;
                        break;
                    }
                    break;
                case "BONUS":
                case 0:
                    message.childGameType = 0;
                    break;
                case "SIGNS":
                case 1:
                    message.childGameType = 1;
                    break;
                }
                if (object.buy != null)
                    message.buy = String(object.buy);
                if (object.D != null)
                    message.D = object.D | 0;
                if (object.tickets != null)
                    message.tickets = object.tickets | 0;
                return message;
            };

            /**
             * Creates a plain object from a BuySlots message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.request.BuySlots
             * @static
             * @param {protobuf.request.BuySlots} message BuySlots
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuySlots.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.childGameType = options.enums === String ? "BONUS" : 0;
                    object.buy = "";
                    object.D = 0;
                    object.tickets = 0;
                }
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    object.childGameType = options.enums === String ? $root.protobuf.request.BuySlots.ChildGameType[message.childGameType] === undefined ? message.childGameType : $root.protobuf.request.BuySlots.ChildGameType[message.childGameType] : message.childGameType;
                if (message.buy != null && message.hasOwnProperty("buy"))
                    object.buy = message.buy;
                if (message.D != null && message.hasOwnProperty("D"))
                    object.D = message.D;
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    object.tickets = message.tickets;
                return object;
            };

            /**
             * Converts this BuySlots to JSON.
             * @function toJSON
             * @memberof protobuf.request.BuySlots
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuySlots.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BuySlots
             * @function getTypeUrl
             * @memberof protobuf.request.BuySlots
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BuySlots.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.request.BuySlots";
            };

            /**
             * ChildGameType enum.
             * @name protobuf.request.BuySlots.ChildGameType
             * @enum {number}
             * @property {number} BONUS=0 BONUS value
             * @property {number} SIGNS=1 SIGNS value
             */
            BuySlots.ChildGameType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "BONUS"] = 0;
                values[valuesById[1] = "SIGNS"] = 1;
                return values;
            })();

            return BuySlots;
        })();

        request.BuyXzc = (function() {

            /**
             * Properties of a BuyXzc.
             * @memberof protobuf.request
             * @interface IBuyXzc
             * @property {protobuf.request.BuyXzc.ChildGameType|null} [childGameType] BuyXzc childGameType
             * @property {string|null} [buy] BuyXzc buy
             * @property {number|null} [tickets] BuyXzc tickets
             */

            /**
             * Constructs a new BuyXzc.
             * @memberof protobuf.request
             * @classdesc Represents a BuyXzc.
             * @implements IBuyXzc
             * @constructor
             * @param {protobuf.request.IBuyXzc=} [properties] Properties to set
             */
            function BuyXzc(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BuyXzc childGameType.
             * @member {protobuf.request.BuyXzc.ChildGameType} childGameType
             * @memberof protobuf.request.BuyXzc
             * @instance
             */
            BuyXzc.prototype.childGameType = 0;

            /**
             * BuyXzc buy.
             * @member {string} buy
             * @memberof protobuf.request.BuyXzc
             * @instance
             */
            BuyXzc.prototype.buy = "";

            /**
             * BuyXzc tickets.
             * @member {number} tickets
             * @memberof protobuf.request.BuyXzc
             * @instance
             */
            BuyXzc.prototype.tickets = 0;

            /**
             * Creates a new BuyXzc instance using the specified properties.
             * @function create
             * @memberof protobuf.request.BuyXzc
             * @static
             * @param {protobuf.request.IBuyXzc=} [properties] Properties to set
             * @returns {protobuf.request.BuyXzc} BuyXzc instance
             */
            BuyXzc.create = function create(properties) {
                return new BuyXzc(properties);
            };

            /**
             * Encodes the specified BuyXzc message. Does not implicitly {@link protobuf.request.BuyXzc.verify|verify} messages.
             * @function encode
             * @memberof protobuf.request.BuyXzc
             * @static
             * @param {protobuf.request.IBuyXzc} message BuyXzc message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyXzc.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.childGameType != null && Object.hasOwnProperty.call(message, "childGameType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.childGameType);
                if (message.buy != null && Object.hasOwnProperty.call(message, "buy"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.buy);
                if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.tickets);
                return writer;
            };

            /**
             * Encodes the specified BuyXzc message, length delimited. Does not implicitly {@link protobuf.request.BuyXzc.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.request.BuyXzc
             * @static
             * @param {protobuf.request.IBuyXzc} message BuyXzc message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyXzc.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuyXzc message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.request.BuyXzc
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.request.BuyXzc} BuyXzc
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyXzc.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.request.BuyXzc();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.childGameType = reader.int32();
                            break;
                        }
                    case 2: {
                            message.buy = reader.string();
                            break;
                        }
                    case 3: {
                            message.tickets = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuyXzc message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.request.BuyXzc
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.request.BuyXzc} BuyXzc
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyXzc.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuyXzc message.
             * @function verify
             * @memberof protobuf.request.BuyXzc
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuyXzc.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    switch (message.childGameType) {
                    default:
                        return "childGameType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.buy != null && message.hasOwnProperty("buy"))
                    if (!$util.isString(message.buy))
                        return "buy: string expected";
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    if (!$util.isInteger(message.tickets))
                        return "tickets: integer expected";
                return null;
            };

            /**
             * Creates a BuyXzc message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.request.BuyXzc
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.request.BuyXzc} BuyXzc
             */
            BuyXzc.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.request.BuyXzc)
                    return object;
                let message = new $root.protobuf.request.BuyXzc();
                switch (object.childGameType) {
                default:
                    if (typeof object.childGameType === "number") {
                        message.childGameType = object.childGameType;
                        break;
                    }
                    break;
                case "ZODIACS":
                case 0:
                    message.childGameType = 0;
                    break;
                case "ELEMENT":
                case 1:
                    message.childGameType = 1;
                    break;
                case "QUALITY":
                case 2:
                    message.childGameType = 2;
                    break;
                case "POLARITY":
                case 3:
                    message.childGameType = 3;
                    break;
                }
                if (object.buy != null)
                    message.buy = String(object.buy);
                if (object.tickets != null)
                    message.tickets = object.tickets | 0;
                return message;
            };

            /**
             * Creates a plain object from a BuyXzc message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.request.BuyXzc
             * @static
             * @param {protobuf.request.BuyXzc} message BuyXzc
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuyXzc.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.childGameType = options.enums === String ? "ZODIACS" : 0;
                    object.buy = "";
                    object.tickets = 0;
                }
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    object.childGameType = options.enums === String ? $root.protobuf.request.BuyXzc.ChildGameType[message.childGameType] === undefined ? message.childGameType : $root.protobuf.request.BuyXzc.ChildGameType[message.childGameType] : message.childGameType;
                if (message.buy != null && message.hasOwnProperty("buy"))
                    object.buy = message.buy;
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    object.tickets = message.tickets;
                return object;
            };

            /**
             * Converts this BuyXzc to JSON.
             * @function toJSON
             * @memberof protobuf.request.BuyXzc
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuyXzc.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BuyXzc
             * @function getTypeUrl
             * @memberof protobuf.request.BuyXzc
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BuyXzc.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.request.BuyXzc";
            };

            /**
             * ChildGameType enum.
             * @name protobuf.request.BuyXzc.ChildGameType
             * @enum {number}
             * @property {number} ZODIACS=0 ZODIACS value
             * @property {number} ELEMENT=1 ELEMENT value
             * @property {number} QUALITY=2 QUALITY value
             * @property {number} POLARITY=3 POLARITY value
             */
            BuyXzc.ChildGameType = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ZODIACS"] = 0;
                values[valuesById[1] = "ELEMENT"] = 1;
                values[valuesById[2] = "QUALITY"] = 2;
                values[valuesById[3] = "POLARITY"] = 3;
                return values;
            })();

            return BuyXzc;
        })();

        request.History = (function() {

            /**
             * Properties of a History.
             * @memberof protobuf.request
             * @interface IHistory
             * @property {number|null} [limit] History limit
             */

            /**
             * Constructs a new History.
             * @memberof protobuf.request
             * @classdesc Represents a History.
             * @implements IHistory
             * @constructor
             * @param {protobuf.request.IHistory=} [properties] Properties to set
             */
            function History(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * History limit.
             * @member {number} limit
             * @memberof protobuf.request.History
             * @instance
             */
            History.prototype.limit = 0;

            /**
             * Creates a new History instance using the specified properties.
             * @function create
             * @memberof protobuf.request.History
             * @static
             * @param {protobuf.request.IHistory=} [properties] Properties to set
             * @returns {protobuf.request.History} History instance
             */
            History.create = function create(properties) {
                return new History(properties);
            };

            /**
             * Encodes the specified History message. Does not implicitly {@link protobuf.request.History.verify|verify} messages.
             * @function encode
             * @memberof protobuf.request.History
             * @static
             * @param {protobuf.request.IHistory} message History message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            History.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
                return writer;
            };

            /**
             * Encodes the specified History message, length delimited. Does not implicitly {@link protobuf.request.History.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.request.History
             * @static
             * @param {protobuf.request.IHistory} message History message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            History.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a History message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.request.History
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.request.History} History
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            History.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.request.History();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.limit = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a History message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.request.History
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.request.History} History
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            History.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a History message.
             * @function verify
             * @memberof protobuf.request.History
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            History.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                return null;
            };

            /**
             * Creates a History message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.request.History
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.request.History} History
             */
            History.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.request.History)
                    return object;
                let message = new $root.protobuf.request.History();
                if (object.limit != null)
                    message.limit = object.limit | 0;
                return message;
            };

            /**
             * Creates a plain object from a History message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.request.History
             * @static
             * @param {protobuf.request.History} message History
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            History.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.limit = 0;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                return object;
            };

            /**
             * Converts this History to JSON.
             * @function toJSON
             * @memberof protobuf.request.History
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            History.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for History
             * @function getTypeUrl
             * @memberof protobuf.request.History
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            History.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.request.History";
            };

            return History;
        })();

        request.BuyAgain = (function() {

            /**
             * Properties of a BuyAgain.
             * @memberof protobuf.request
             * @interface IBuyAgain
             * @property {number|Long|null} [orderId] BuyAgain orderId
             */

            /**
             * Constructs a new BuyAgain.
             * @memberof protobuf.request
             * @classdesc Represents a BuyAgain.
             * @implements IBuyAgain
             * @constructor
             * @param {protobuf.request.IBuyAgain=} [properties] Properties to set
             */
            function BuyAgain(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BuyAgain orderId.
             * @member {number|Long} orderId
             * @memberof protobuf.request.BuyAgain
             * @instance
             */
            BuyAgain.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new BuyAgain instance using the specified properties.
             * @function create
             * @memberof protobuf.request.BuyAgain
             * @static
             * @param {protobuf.request.IBuyAgain=} [properties] Properties to set
             * @returns {protobuf.request.BuyAgain} BuyAgain instance
             */
            BuyAgain.create = function create(properties) {
                return new BuyAgain(properties);
            };

            /**
             * Encodes the specified BuyAgain message. Does not implicitly {@link protobuf.request.BuyAgain.verify|verify} messages.
             * @function encode
             * @memberof protobuf.request.BuyAgain
             * @static
             * @param {protobuf.request.IBuyAgain} message BuyAgain message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyAgain.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.orderId);
                return writer;
            };

            /**
             * Encodes the specified BuyAgain message, length delimited. Does not implicitly {@link protobuf.request.BuyAgain.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.request.BuyAgain
             * @static
             * @param {protobuf.request.IBuyAgain} message BuyAgain message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyAgain.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuyAgain message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.request.BuyAgain
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.request.BuyAgain} BuyAgain
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyAgain.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.request.BuyAgain();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.orderId = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuyAgain message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.request.BuyAgain
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.request.BuyAgain} BuyAgain
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyAgain.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuyAgain message.
             * @function verify
             * @memberof protobuf.request.BuyAgain
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuyAgain.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                return null;
            };

            /**
             * Creates a BuyAgain message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.request.BuyAgain
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.request.BuyAgain} BuyAgain
             */
            BuyAgain.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.request.BuyAgain)
                    return object;
                let message = new $root.protobuf.request.BuyAgain();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = false;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a BuyAgain message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.request.BuyAgain
             * @static
             * @param {protobuf.request.BuyAgain} message BuyAgain
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuyAgain.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber() : message.orderId;
                return object;
            };

            /**
             * Converts this BuyAgain to JSON.
             * @function toJSON
             * @memberof protobuf.request.BuyAgain
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuyAgain.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BuyAgain
             * @function getTypeUrl
             * @memberof protobuf.request.BuyAgain
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BuyAgain.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.request.BuyAgain";
            };

            return BuyAgain;
        })();

        return request;
    })();

    protobuf.response = (function() {

        /**
         * Namespace response.
         * @memberof protobuf
         * @namespace
         */
        const response = {};

        /**
         * Code enum.
         * @name protobuf.response.Code
         * @enum {number}
         * @property {number} SUCCESS=0 SUCCESS value
         * @property {number} REQ_MESSAGE_STRUCT_ERR=1 REQ_MESSAGE_STRUCT_ERR value
         * @property {number} SERVER_ERR=2 SERVER_ERR value
         * @property {number} NOT_ALLOW_BUY_THIS_MOMENT=3 NOT_ALLOW_BUY_THIS_MOMENT value
         * @property {number} BUY_NUM_NOT_CORRECT=4 BUY_NUM_NOT_CORRECT value
         * @property {number} BUY_ORDER_AMOUNT_ERR=5 BUY_ORDER_AMOUNT_ERR value
         * @property {number} USER_BALANCE_NOT_ENOUGH=6 USER_BALANCE_NOT_ENOUGH value
         * @property {number} BUY_TOO_FREQUENTLY=7 BUY_TOO_FREQUENTLY value
         * @property {number} AGENT_SCORE_NOT_ENOUGH=8 AGENT_SCORE_NOT_ENOUGH value
         * @property {number} SINGLE_PERIODS_AMOUNT_LIMIT=9 SINGLE_PERIODS_AMOUNT_LIMIT value
         * @property {number} NOT_IN_ROOM=10 NOT_IN_ROOM value
         * @property {number} MESSAGE_TYPE_NOT_SUPPORT=11 MESSAGE_TYPE_NOT_SUPPORT value
         * @property {number} LOTTERY_NOT_SUPPORT=12 LOTTERY_NOT_SUPPORT value
         */
        response.Code = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SUCCESS"] = 0;
            values[valuesById[1] = "REQ_MESSAGE_STRUCT_ERR"] = 1;
            values[valuesById[2] = "SERVER_ERR"] = 2;
            values[valuesById[3] = "NOT_ALLOW_BUY_THIS_MOMENT"] = 3;
            values[valuesById[4] = "BUY_NUM_NOT_CORRECT"] = 4;
            values[valuesById[5] = "BUY_ORDER_AMOUNT_ERR"] = 5;
            values[valuesById[6] = "USER_BALANCE_NOT_ENOUGH"] = 6;
            values[valuesById[7] = "BUY_TOO_FREQUENTLY"] = 7;
            values[valuesById[8] = "AGENT_SCORE_NOT_ENOUGH"] = 8;
            values[valuesById[9] = "SINGLE_PERIODS_AMOUNT_LIMIT"] = 9;
            values[valuesById[10] = "NOT_IN_ROOM"] = 10;
            values[valuesById[11] = "MESSAGE_TYPE_NOT_SUPPORT"] = 11;
            values[valuesById[12] = "LOTTERY_NOT_SUPPORT"] = 12;
            return values;
        })();

        /**
         * Suit enum.
         * @name protobuf.response.Suit
         * @enum {number}
         * @property {number} CLUBS=0 CLUBS value
         * @property {number} DIAMONDS=1 DIAMONDS value
         * @property {number} HEARTS=2 HEARTS value
         * @property {number} SPADES=3 SPADES value
         */
        response.Suit = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CLUBS"] = 0;
            values[valuesById[1] = "DIAMONDS"] = 1;
            values[valuesById[2] = "HEARTS"] = 2;
            values[valuesById[3] = "SPADES"] = 3;
            return values;
        })();

        response.CommonResponse = (function() {

            /**
             * Properties of a CommonResponse.
             * @memberof protobuf.response
             * @interface ICommonResponse
             * @property {number|null} [msgId] CommonResponse msgId
             * @property {protobuf.common.MessageType|null} [type] CommonResponse type
             * @property {protobuf.response.Code|null} [code] CommonResponse code
             * @property {protobuf.common.Lottery|null} [lottery] CommonResponse lottery
             * @property {boolean|null} [hasPayload] CommonResponse hasPayload
             * @property {Uint8Array|null} [payload] CommonResponse payload
             */

            /**
             * Constructs a new CommonResponse.
             * @memberof protobuf.response
             * @classdesc Represents a CommonResponse.
             * @implements ICommonResponse
             * @constructor
             * @param {protobuf.response.ICommonResponse=} [properties] Properties to set
             */
            function CommonResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CommonResponse msgId.
             * @member {number} msgId
             * @memberof protobuf.response.CommonResponse
             * @instance
             */
            CommonResponse.prototype.msgId = 0;

            /**
             * CommonResponse type.
             * @member {protobuf.common.MessageType} type
             * @memberof protobuf.response.CommonResponse
             * @instance
             */
            CommonResponse.prototype.type = 0;

            /**
             * CommonResponse code.
             * @member {protobuf.response.Code} code
             * @memberof protobuf.response.CommonResponse
             * @instance
             */
            CommonResponse.prototype.code = 0;

            /**
             * CommonResponse lottery.
             * @member {protobuf.common.Lottery} lottery
             * @memberof protobuf.response.CommonResponse
             * @instance
             */
            CommonResponse.prototype.lottery = 0;

            /**
             * CommonResponse hasPayload.
             * @member {boolean} hasPayload
             * @memberof protobuf.response.CommonResponse
             * @instance
             */
            CommonResponse.prototype.hasPayload = false;

            /**
             * CommonResponse payload.
             * @member {Uint8Array} payload
             * @memberof protobuf.response.CommonResponse
             * @instance
             */
            CommonResponse.prototype.payload = $util.newBuffer([]);

            /**
             * Creates a new CommonResponse instance using the specified properties.
             * @function create
             * @memberof protobuf.response.CommonResponse
             * @static
             * @param {protobuf.response.ICommonResponse=} [properties] Properties to set
             * @returns {protobuf.response.CommonResponse} CommonResponse instance
             */
            CommonResponse.create = function create(properties) {
                return new CommonResponse(properties);
            };

            /**
             * Encodes the specified CommonResponse message. Does not implicitly {@link protobuf.response.CommonResponse.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.CommonResponse
             * @static
             * @param {protobuf.response.ICommonResponse} message CommonResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CommonResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.msgId);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.code);
                if (message.lottery != null && Object.hasOwnProperty.call(message, "lottery"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lottery);
                if (message.hasPayload != null && Object.hasOwnProperty.call(message, "hasPayload"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.hasPayload);
                if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                    writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.payload);
                return writer;
            };

            /**
             * Encodes the specified CommonResponse message, length delimited. Does not implicitly {@link protobuf.response.CommonResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.CommonResponse
             * @static
             * @param {protobuf.response.ICommonResponse} message CommonResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CommonResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CommonResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.CommonResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.CommonResponse} CommonResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CommonResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.CommonResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.msgId = reader.int32();
                            break;
                        }
                    case 2: {
                            message.type = reader.int32();
                            break;
                        }
                    case 3: {
                            message.code = reader.int32();
                            break;
                        }
                    case 4: {
                            message.lottery = reader.int32();
                            break;
                        }
                    case 5: {
                            message.hasPayload = reader.bool();
                            break;
                        }
                    case 6: {
                            message.payload = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CommonResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.CommonResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.CommonResponse} CommonResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CommonResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CommonResponse message.
             * @function verify
             * @memberof protobuf.response.CommonResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CommonResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    if (!$util.isInteger(message.msgId))
                        return "msgId: integer expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        break;
                    }
                if (message.lottery != null && message.hasOwnProperty("lottery"))
                    switch (message.lottery) {
                    default:
                        return "lottery: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.hasPayload != null && message.hasOwnProperty("hasPayload"))
                    if (typeof message.hasPayload !== "boolean")
                        return "hasPayload: boolean expected";
                if (message.payload != null && message.hasOwnProperty("payload"))
                    if (!(message.payload && typeof message.payload.length === "number" || $util.isString(message.payload)))
                        return "payload: buffer expected";
                return null;
            };

            /**
             * Creates a CommonResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.CommonResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.CommonResponse} CommonResponse
             */
            CommonResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.CommonResponse)
                    return object;
                let message = new $root.protobuf.response.CommonResponse();
                if (object.msgId != null)
                    message.msgId = object.msgId | 0;
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "CALCULATE":
                case 0:
                    message.type = 0;
                    break;
                case "BUY":
                case 1:
                    message.type = 1;
                    break;
                case "HIS_LOTTO":
                case 2:
                    message.type = 2;
                    break;
                case "HIS_ORDER":
                case 3:
                    message.type = 3;
                    break;
                case "BUY_AGAIN":
                case 4:
                    message.type = 4;
                    break;
                }
                switch (object.code) {
                default:
                    if (typeof object.code === "number") {
                        message.code = object.code;
                        break;
                    }
                    break;
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "REQ_MESSAGE_STRUCT_ERR":
                case 1:
                    message.code = 1;
                    break;
                case "SERVER_ERR":
                case 2:
                    message.code = 2;
                    break;
                case "NOT_ALLOW_BUY_THIS_MOMENT":
                case 3:
                    message.code = 3;
                    break;
                case "BUY_NUM_NOT_CORRECT":
                case 4:
                    message.code = 4;
                    break;
                case "BUY_ORDER_AMOUNT_ERR":
                case 5:
                    message.code = 5;
                    break;
                case "USER_BALANCE_NOT_ENOUGH":
                case 6:
                    message.code = 6;
                    break;
                case "BUY_TOO_FREQUENTLY":
                case 7:
                    message.code = 7;
                    break;
                case "AGENT_SCORE_NOT_ENOUGH":
                case 8:
                    message.code = 8;
                    break;
                case "SINGLE_PERIODS_AMOUNT_LIMIT":
                case 9:
                    message.code = 9;
                    break;
                case "NOT_IN_ROOM":
                case 10:
                    message.code = 10;
                    break;
                case "MESSAGE_TYPE_NOT_SUPPORT":
                case 11:
                    message.code = 11;
                    break;
                case "LOTTERY_NOT_SUPPORT":
                case 12:
                    message.code = 12;
                    break;
                }
                switch (object.lottery) {
                default:
                    if (typeof object.lottery === "number") {
                        message.lottery = object.lottery;
                        break;
                    }
                    break;
                case "SSC":
                case 0:
                    message.lottery = 0;
                    break;
                case "LHC":
                case 1:
                    message.lottery = 1;
                    break;
                case "XZC":
                case 2:
                    message.lottery = 2;
                    break;
                case "FC3D":
                case 3:
                    message.lottery = 3;
                    break;
                case "TEXAS":
                case 4:
                    message.lottery = 4;
                    break;
                case "SLOTS":
                case 5:
                    message.lottery = 5;
                    break;
                }
                if (object.hasPayload != null)
                    message.hasPayload = Boolean(object.hasPayload);
                if (object.payload != null)
                    if (typeof object.payload === "string")
                        $util.base64.decode(object.payload, message.payload = $util.newBuffer($util.base64.length(object.payload)), 0);
                    else if (object.payload.length >= 0)
                        message.payload = object.payload;
                return message;
            };

            /**
             * Creates a plain object from a CommonResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.CommonResponse
             * @static
             * @param {protobuf.response.CommonResponse} message CommonResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CommonResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.msgId = 0;
                    object.type = options.enums === String ? "CALCULATE" : 0;
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.lottery = options.enums === String ? "SSC" : 0;
                    object.hasPayload = false;
                    if (options.bytes === String)
                        object.payload = "";
                    else {
                        object.payload = [];
                        if (options.bytes !== Array)
                            object.payload = $util.newBuffer(object.payload);
                    }
                }
                if (message.msgId != null && message.hasOwnProperty("msgId"))
                    object.msgId = message.msgId;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.protobuf.common.MessageType[message.type] === undefined ? message.type : $root.protobuf.common.MessageType[message.type] : message.type;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.protobuf.response.Code[message.code] === undefined ? message.code : $root.protobuf.response.Code[message.code] : message.code;
                if (message.lottery != null && message.hasOwnProperty("lottery"))
                    object.lottery = options.enums === String ? $root.protobuf.common.Lottery[message.lottery] === undefined ? message.lottery : $root.protobuf.common.Lottery[message.lottery] : message.lottery;
                if (message.hasPayload != null && message.hasOwnProperty("hasPayload"))
                    object.hasPayload = message.hasPayload;
                if (message.payload != null && message.hasOwnProperty("payload"))
                    object.payload = options.bytes === String ? $util.base64.encode(message.payload, 0, message.payload.length) : options.bytes === Array ? Array.prototype.slice.call(message.payload) : message.payload;
                return object;
            };

            /**
             * Converts this CommonResponse to JSON.
             * @function toJSON
             * @memberof protobuf.response.CommonResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CommonResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CommonResponse
             * @function getTypeUrl
             * @memberof protobuf.response.CommonResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CommonResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.CommonResponse";
            };

            return CommonResponse;
        })();

        response.CalculateResponse = (function() {

            /**
             * Properties of a CalculateResponse.
             * @memberof protobuf.response
             * @interface ICalculateResponse
             * @property {number|null} [quantity] CalculateResponse quantity
             * @property {number|null} [tickets] CalculateResponse tickets
             * @property {number|Long|null} [price] CalculateResponse price
             * @property {number|null} [hitPrizeMax] CalculateResponse hitPrizeMax
             * @property {number|null} [unitPrice] CalculateResponse unitPrice
             */

            /**
             * Constructs a new CalculateResponse.
             * @memberof protobuf.response
             * @classdesc Represents a CalculateResponse.
             * @implements ICalculateResponse
             * @constructor
             * @param {protobuf.response.ICalculateResponse=} [properties] Properties to set
             */
            function CalculateResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CalculateResponse quantity.
             * @member {number} quantity
             * @memberof protobuf.response.CalculateResponse
             * @instance
             */
            CalculateResponse.prototype.quantity = 0;

            /**
             * CalculateResponse tickets.
             * @member {number} tickets
             * @memberof protobuf.response.CalculateResponse
             * @instance
             */
            CalculateResponse.prototype.tickets = 0;

            /**
             * CalculateResponse price.
             * @member {number|Long} price
             * @memberof protobuf.response.CalculateResponse
             * @instance
             */
            CalculateResponse.prototype.price = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * CalculateResponse hitPrizeMax.
             * @member {number} hitPrizeMax
             * @memberof protobuf.response.CalculateResponse
             * @instance
             */
            CalculateResponse.prototype.hitPrizeMax = 0;

            /**
             * CalculateResponse unitPrice.
             * @member {number} unitPrice
             * @memberof protobuf.response.CalculateResponse
             * @instance
             */
            CalculateResponse.prototype.unitPrice = 0;

            /**
             * Creates a new CalculateResponse instance using the specified properties.
             * @function create
             * @memberof protobuf.response.CalculateResponse
             * @static
             * @param {protobuf.response.ICalculateResponse=} [properties] Properties to set
             * @returns {protobuf.response.CalculateResponse} CalculateResponse instance
             */
            CalculateResponse.create = function create(properties) {
                return new CalculateResponse(properties);
            };

            /**
             * Encodes the specified CalculateResponse message. Does not implicitly {@link protobuf.response.CalculateResponse.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.CalculateResponse
             * @static
             * @param {protobuf.response.ICalculateResponse} message CalculateResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CalculateResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.quantity);
                if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.tickets);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.price);
                if (message.hitPrizeMax != null && Object.hasOwnProperty.call(message, "hitPrizeMax"))
                    writer.uint32(/* id 4, wireType 1 =*/33).double(message.hitPrizeMax);
                if (message.unitPrice != null && Object.hasOwnProperty.call(message, "unitPrice"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.unitPrice);
                return writer;
            };

            /**
             * Encodes the specified CalculateResponse message, length delimited. Does not implicitly {@link protobuf.response.CalculateResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.CalculateResponse
             * @static
             * @param {protobuf.response.ICalculateResponse} message CalculateResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CalculateResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CalculateResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.CalculateResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.CalculateResponse} CalculateResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CalculateResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.CalculateResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.quantity = reader.int32();
                            break;
                        }
                    case 2: {
                            message.tickets = reader.int32();
                            break;
                        }
                    case 3: {
                            message.price = reader.int64();
                            break;
                        }
                    case 4: {
                            message.hitPrizeMax = reader.double();
                            break;
                        }
                    case 5: {
                            message.unitPrice = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CalculateResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.CalculateResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.CalculateResponse} CalculateResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CalculateResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CalculateResponse message.
             * @function verify
             * @memberof protobuf.response.CalculateResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CalculateResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (!$util.isInteger(message.quantity))
                        return "quantity: integer expected";
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    if (!$util.isInteger(message.tickets))
                        return "tickets: integer expected";
                if (message.price != null && message.hasOwnProperty("price"))
                    if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                        return "price: integer|Long expected";
                if (message.hitPrizeMax != null && message.hasOwnProperty("hitPrizeMax"))
                    if (typeof message.hitPrizeMax !== "number")
                        return "hitPrizeMax: number expected";
                if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                    if (!$util.isInteger(message.unitPrice))
                        return "unitPrice: integer expected";
                return null;
            };

            /**
             * Creates a CalculateResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.CalculateResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.CalculateResponse} CalculateResponse
             */
            CalculateResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.CalculateResponse)
                    return object;
                let message = new $root.protobuf.response.CalculateResponse();
                if (object.quantity != null)
                    message.quantity = object.quantity | 0;
                if (object.tickets != null)
                    message.tickets = object.tickets | 0;
                if (object.price != null)
                    if ($util.Long)
                        (message.price = $util.Long.fromValue(object.price)).unsigned = false;
                    else if (typeof object.price === "string")
                        message.price = parseInt(object.price, 10);
                    else if (typeof object.price === "number")
                        message.price = object.price;
                    else if (typeof object.price === "object")
                        message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
                if (object.hitPrizeMax != null)
                    message.hitPrizeMax = Number(object.hitPrizeMax);
                if (object.unitPrice != null)
                    message.unitPrice = object.unitPrice | 0;
                return message;
            };

            /**
             * Creates a plain object from a CalculateResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.CalculateResponse
             * @static
             * @param {protobuf.response.CalculateResponse} message CalculateResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CalculateResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.quantity = 0;
                    object.tickets = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.price = options.longs === String ? "0" : 0;
                    object.hitPrizeMax = 0;
                    object.unitPrice = 0;
                }
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = message.quantity;
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    object.tickets = message.tickets;
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price === "number")
                        object.price = options.longs === String ? String(message.price) : message.price;
                    else
                        object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber() : message.price;
                if (message.hitPrizeMax != null && message.hasOwnProperty("hitPrizeMax"))
                    object.hitPrizeMax = options.json && !isFinite(message.hitPrizeMax) ? String(message.hitPrizeMax) : message.hitPrizeMax;
                if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                    object.unitPrice = message.unitPrice;
                return object;
            };

            /**
             * Converts this CalculateResponse to JSON.
             * @function toJSON
             * @memberof protobuf.response.CalculateResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CalculateResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CalculateResponse
             * @function getTypeUrl
             * @memberof protobuf.response.CalculateResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CalculateResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.CalculateResponse";
            };

            return CalculateResponse;
        })();

        response.HisLottoResponse = (function() {

            /**
             * Properties of a HisLottoResponse.
             * @memberof protobuf.response
             * @interface IHisLottoResponse
             * @property {Array.<protobuf.response.ILhcHisLotto>|null} [lhcRecords] HisLottoResponse lhcRecords
             * @property {Array.<protobuf.response.IXzcHisLotto>|null} [xzcRecords] HisLottoResponse xzcRecords
             * @property {Array.<protobuf.response.IFc3dHisLotto>|null} [fc3dRecords] HisLottoResponse fc3dRecords
             * @property {Array.<protobuf.response.ISscHisLotto>|null} [sscRecords] HisLottoResponse sscRecords
             * @property {Array.<protobuf.response.ISlotsHisLotto>|null} [slotsRecords] HisLottoResponse slotsRecords
             * @property {Array.<protobuf.response.ITexasHisLotto>|null} [texasRecords] HisLottoResponse texasRecords
             */

            /**
             * Constructs a new HisLottoResponse.
             * @memberof protobuf.response
             * @classdesc Represents a HisLottoResponse.
             * @implements IHisLottoResponse
             * @constructor
             * @param {protobuf.response.IHisLottoResponse=} [properties] Properties to set
             */
            function HisLottoResponse(properties) {
                this.lhcRecords = [];
                this.xzcRecords = [];
                this.fc3dRecords = [];
                this.sscRecords = [];
                this.slotsRecords = [];
                this.texasRecords = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * HisLottoResponse lhcRecords.
             * @member {Array.<protobuf.response.ILhcHisLotto>} lhcRecords
             * @memberof protobuf.response.HisLottoResponse
             * @instance
             */
            HisLottoResponse.prototype.lhcRecords = $util.emptyArray;

            /**
             * HisLottoResponse xzcRecords.
             * @member {Array.<protobuf.response.IXzcHisLotto>} xzcRecords
             * @memberof protobuf.response.HisLottoResponse
             * @instance
             */
            HisLottoResponse.prototype.xzcRecords = $util.emptyArray;

            /**
             * HisLottoResponse fc3dRecords.
             * @member {Array.<protobuf.response.IFc3dHisLotto>} fc3dRecords
             * @memberof protobuf.response.HisLottoResponse
             * @instance
             */
            HisLottoResponse.prototype.fc3dRecords = $util.emptyArray;

            /**
             * HisLottoResponse sscRecords.
             * @member {Array.<protobuf.response.ISscHisLotto>} sscRecords
             * @memberof protobuf.response.HisLottoResponse
             * @instance
             */
            HisLottoResponse.prototype.sscRecords = $util.emptyArray;

            /**
             * HisLottoResponse slotsRecords.
             * @member {Array.<protobuf.response.ISlotsHisLotto>} slotsRecords
             * @memberof protobuf.response.HisLottoResponse
             * @instance
             */
            HisLottoResponse.prototype.slotsRecords = $util.emptyArray;

            /**
             * HisLottoResponse texasRecords.
             * @member {Array.<protobuf.response.ITexasHisLotto>} texasRecords
             * @memberof protobuf.response.HisLottoResponse
             * @instance
             */
            HisLottoResponse.prototype.texasRecords = $util.emptyArray;

            /**
             * Creates a new HisLottoResponse instance using the specified properties.
             * @function create
             * @memberof protobuf.response.HisLottoResponse
             * @static
             * @param {protobuf.response.IHisLottoResponse=} [properties] Properties to set
             * @returns {protobuf.response.HisLottoResponse} HisLottoResponse instance
             */
            HisLottoResponse.create = function create(properties) {
                return new HisLottoResponse(properties);
            };

            /**
             * Encodes the specified HisLottoResponse message. Does not implicitly {@link protobuf.response.HisLottoResponse.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.HisLottoResponse
             * @static
             * @param {protobuf.response.IHisLottoResponse} message HisLottoResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HisLottoResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.lhcRecords != null && message.lhcRecords.length)
                    for (let i = 0; i < message.lhcRecords.length; ++i)
                        $root.protobuf.response.LhcHisLotto.encode(message.lhcRecords[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.xzcRecords != null && message.xzcRecords.length)
                    for (let i = 0; i < message.xzcRecords.length; ++i)
                        $root.protobuf.response.XzcHisLotto.encode(message.xzcRecords[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.fc3dRecords != null && message.fc3dRecords.length)
                    for (let i = 0; i < message.fc3dRecords.length; ++i)
                        $root.protobuf.response.Fc3dHisLotto.encode(message.fc3dRecords[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.sscRecords != null && message.sscRecords.length)
                    for (let i = 0; i < message.sscRecords.length; ++i)
                        $root.protobuf.response.SscHisLotto.encode(message.sscRecords[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.slotsRecords != null && message.slotsRecords.length)
                    for (let i = 0; i < message.slotsRecords.length; ++i)
                        $root.protobuf.response.SlotsHisLotto.encode(message.slotsRecords[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.texasRecords != null && message.texasRecords.length)
                    for (let i = 0; i < message.texasRecords.length; ++i)
                        $root.protobuf.response.TexasHisLotto.encode(message.texasRecords[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified HisLottoResponse message, length delimited. Does not implicitly {@link protobuf.response.HisLottoResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.HisLottoResponse
             * @static
             * @param {protobuf.response.IHisLottoResponse} message HisLottoResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HisLottoResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a HisLottoResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.HisLottoResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.HisLottoResponse} HisLottoResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HisLottoResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.HisLottoResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.lhcRecords && message.lhcRecords.length))
                                message.lhcRecords = [];
                            message.lhcRecords.push($root.protobuf.response.LhcHisLotto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(message.xzcRecords && message.xzcRecords.length))
                                message.xzcRecords = [];
                            message.xzcRecords.push($root.protobuf.response.XzcHisLotto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            if (!(message.fc3dRecords && message.fc3dRecords.length))
                                message.fc3dRecords = [];
                            message.fc3dRecords.push($root.protobuf.response.Fc3dHisLotto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            if (!(message.sscRecords && message.sscRecords.length))
                                message.sscRecords = [];
                            message.sscRecords.push($root.protobuf.response.SscHisLotto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.slotsRecords && message.slotsRecords.length))
                                message.slotsRecords = [];
                            message.slotsRecords.push($root.protobuf.response.SlotsHisLotto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.texasRecords && message.texasRecords.length))
                                message.texasRecords = [];
                            message.texasRecords.push($root.protobuf.response.TexasHisLotto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a HisLottoResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.HisLottoResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.HisLottoResponse} HisLottoResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HisLottoResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a HisLottoResponse message.
             * @function verify
             * @memberof protobuf.response.HisLottoResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HisLottoResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.lhcRecords != null && message.hasOwnProperty("lhcRecords")) {
                    if (!Array.isArray(message.lhcRecords))
                        return "lhcRecords: array expected";
                    for (let i = 0; i < message.lhcRecords.length; ++i) {
                        let error = $root.protobuf.response.LhcHisLotto.verify(message.lhcRecords[i]);
                        if (error)
                            return "lhcRecords." + error;
                    }
                }
                if (message.xzcRecords != null && message.hasOwnProperty("xzcRecords")) {
                    if (!Array.isArray(message.xzcRecords))
                        return "xzcRecords: array expected";
                    for (let i = 0; i < message.xzcRecords.length; ++i) {
                        let error = $root.protobuf.response.XzcHisLotto.verify(message.xzcRecords[i]);
                        if (error)
                            return "xzcRecords." + error;
                    }
                }
                if (message.fc3dRecords != null && message.hasOwnProperty("fc3dRecords")) {
                    if (!Array.isArray(message.fc3dRecords))
                        return "fc3dRecords: array expected";
                    for (let i = 0; i < message.fc3dRecords.length; ++i) {
                        let error = $root.protobuf.response.Fc3dHisLotto.verify(message.fc3dRecords[i]);
                        if (error)
                            return "fc3dRecords." + error;
                    }
                }
                if (message.sscRecords != null && message.hasOwnProperty("sscRecords")) {
                    if (!Array.isArray(message.sscRecords))
                        return "sscRecords: array expected";
                    for (let i = 0; i < message.sscRecords.length; ++i) {
                        let error = $root.protobuf.response.SscHisLotto.verify(message.sscRecords[i]);
                        if (error)
                            return "sscRecords." + error;
                    }
                }
                if (message.slotsRecords != null && message.hasOwnProperty("slotsRecords")) {
                    if (!Array.isArray(message.slotsRecords))
                        return "slotsRecords: array expected";
                    for (let i = 0; i < message.slotsRecords.length; ++i) {
                        let error = $root.protobuf.response.SlotsHisLotto.verify(message.slotsRecords[i]);
                        if (error)
                            return "slotsRecords." + error;
                    }
                }
                if (message.texasRecords != null && message.hasOwnProperty("texasRecords")) {
                    if (!Array.isArray(message.texasRecords))
                        return "texasRecords: array expected";
                    for (let i = 0; i < message.texasRecords.length; ++i) {
                        let error = $root.protobuf.response.TexasHisLotto.verify(message.texasRecords[i]);
                        if (error)
                            return "texasRecords." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a HisLottoResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.HisLottoResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.HisLottoResponse} HisLottoResponse
             */
            HisLottoResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.HisLottoResponse)
                    return object;
                let message = new $root.protobuf.response.HisLottoResponse();
                if (object.lhcRecords) {
                    if (!Array.isArray(object.lhcRecords))
                        throw TypeError(".protobuf.response.HisLottoResponse.lhcRecords: array expected");
                    message.lhcRecords = [];
                    for (let i = 0; i < object.lhcRecords.length; ++i) {
                        if (typeof object.lhcRecords[i] !== "object")
                            throw TypeError(".protobuf.response.HisLottoResponse.lhcRecords: object expected");
                        message.lhcRecords[i] = $root.protobuf.response.LhcHisLotto.fromObject(object.lhcRecords[i]);
                    }
                }
                if (object.xzcRecords) {
                    if (!Array.isArray(object.xzcRecords))
                        throw TypeError(".protobuf.response.HisLottoResponse.xzcRecords: array expected");
                    message.xzcRecords = [];
                    for (let i = 0; i < object.xzcRecords.length; ++i) {
                        if (typeof object.xzcRecords[i] !== "object")
                            throw TypeError(".protobuf.response.HisLottoResponse.xzcRecords: object expected");
                        message.xzcRecords[i] = $root.protobuf.response.XzcHisLotto.fromObject(object.xzcRecords[i]);
                    }
                }
                if (object.fc3dRecords) {
                    if (!Array.isArray(object.fc3dRecords))
                        throw TypeError(".protobuf.response.HisLottoResponse.fc3dRecords: array expected");
                    message.fc3dRecords = [];
                    for (let i = 0; i < object.fc3dRecords.length; ++i) {
                        if (typeof object.fc3dRecords[i] !== "object")
                            throw TypeError(".protobuf.response.HisLottoResponse.fc3dRecords: object expected");
                        message.fc3dRecords[i] = $root.protobuf.response.Fc3dHisLotto.fromObject(object.fc3dRecords[i]);
                    }
                }
                if (object.sscRecords) {
                    if (!Array.isArray(object.sscRecords))
                        throw TypeError(".protobuf.response.HisLottoResponse.sscRecords: array expected");
                    message.sscRecords = [];
                    for (let i = 0; i < object.sscRecords.length; ++i) {
                        if (typeof object.sscRecords[i] !== "object")
                            throw TypeError(".protobuf.response.HisLottoResponse.sscRecords: object expected");
                        message.sscRecords[i] = $root.protobuf.response.SscHisLotto.fromObject(object.sscRecords[i]);
                    }
                }
                if (object.slotsRecords) {
                    if (!Array.isArray(object.slotsRecords))
                        throw TypeError(".protobuf.response.HisLottoResponse.slotsRecords: array expected");
                    message.slotsRecords = [];
                    for (let i = 0; i < object.slotsRecords.length; ++i) {
                        if (typeof object.slotsRecords[i] !== "object")
                            throw TypeError(".protobuf.response.HisLottoResponse.slotsRecords: object expected");
                        message.slotsRecords[i] = $root.protobuf.response.SlotsHisLotto.fromObject(object.slotsRecords[i]);
                    }
                }
                if (object.texasRecords) {
                    if (!Array.isArray(object.texasRecords))
                        throw TypeError(".protobuf.response.HisLottoResponse.texasRecords: array expected");
                    message.texasRecords = [];
                    for (let i = 0; i < object.texasRecords.length; ++i) {
                        if (typeof object.texasRecords[i] !== "object")
                            throw TypeError(".protobuf.response.HisLottoResponse.texasRecords: object expected");
                        message.texasRecords[i] = $root.protobuf.response.TexasHisLotto.fromObject(object.texasRecords[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a HisLottoResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.HisLottoResponse
             * @static
             * @param {protobuf.response.HisLottoResponse} message HisLottoResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HisLottoResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.lhcRecords = [];
                    object.xzcRecords = [];
                    object.fc3dRecords = [];
                    object.sscRecords = [];
                    object.slotsRecords = [];
                    object.texasRecords = [];
                }
                if (message.lhcRecords && message.lhcRecords.length) {
                    object.lhcRecords = [];
                    for (let j = 0; j < message.lhcRecords.length; ++j)
                        object.lhcRecords[j] = $root.protobuf.response.LhcHisLotto.toObject(message.lhcRecords[j], options);
                }
                if (message.xzcRecords && message.xzcRecords.length) {
                    object.xzcRecords = [];
                    for (let j = 0; j < message.xzcRecords.length; ++j)
                        object.xzcRecords[j] = $root.protobuf.response.XzcHisLotto.toObject(message.xzcRecords[j], options);
                }
                if (message.fc3dRecords && message.fc3dRecords.length) {
                    object.fc3dRecords = [];
                    for (let j = 0; j < message.fc3dRecords.length; ++j)
                        object.fc3dRecords[j] = $root.protobuf.response.Fc3dHisLotto.toObject(message.fc3dRecords[j], options);
                }
                if (message.sscRecords && message.sscRecords.length) {
                    object.sscRecords = [];
                    for (let j = 0; j < message.sscRecords.length; ++j)
                        object.sscRecords[j] = $root.protobuf.response.SscHisLotto.toObject(message.sscRecords[j], options);
                }
                if (message.slotsRecords && message.slotsRecords.length) {
                    object.slotsRecords = [];
                    for (let j = 0; j < message.slotsRecords.length; ++j)
                        object.slotsRecords[j] = $root.protobuf.response.SlotsHisLotto.toObject(message.slotsRecords[j], options);
                }
                if (message.texasRecords && message.texasRecords.length) {
                    object.texasRecords = [];
                    for (let j = 0; j < message.texasRecords.length; ++j)
                        object.texasRecords[j] = $root.protobuf.response.TexasHisLotto.toObject(message.texasRecords[j], options);
                }
                return object;
            };

            /**
             * Converts this HisLottoResponse to JSON.
             * @function toJSON
             * @memberof protobuf.response.HisLottoResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HisLottoResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for HisLottoResponse
             * @function getTypeUrl
             * @memberof protobuf.response.HisLottoResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            HisLottoResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.HisLottoResponse";
            };

            return HisLottoResponse;
        })();

        response.LhcHisLotto = (function() {

            /**
             * Properties of a LhcHisLotto.
             * @memberof protobuf.response
             * @interface ILhcHisLotto
             * @property {string|null} [periods] LhcHisLotto periods
             * @property {string|null} [mark] LhcHisLotto mark
             * @property {string|null} [fit6] LhcHisLotto fit6
             * @property {string|null} [fit3] LhcHisLotto fit3
             * @property {string|null} [season] LhcHisLotto season
             * @property {string|null} [dayNight] LhcHisLotto dayNight
             */

            /**
             * Constructs a new LhcHisLotto.
             * @memberof protobuf.response
             * @classdesc Represents a LhcHisLotto.
             * @implements ILhcHisLotto
             * @constructor
             * @param {protobuf.response.ILhcHisLotto=} [properties] Properties to set
             */
            function LhcHisLotto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LhcHisLotto periods.
             * @member {string} periods
             * @memberof protobuf.response.LhcHisLotto
             * @instance
             */
            LhcHisLotto.prototype.periods = "";

            /**
             * LhcHisLotto mark.
             * @member {string} mark
             * @memberof protobuf.response.LhcHisLotto
             * @instance
             */
            LhcHisLotto.prototype.mark = "";

            /**
             * LhcHisLotto fit6.
             * @member {string} fit6
             * @memberof protobuf.response.LhcHisLotto
             * @instance
             */
            LhcHisLotto.prototype.fit6 = "";

            /**
             * LhcHisLotto fit3.
             * @member {string} fit3
             * @memberof protobuf.response.LhcHisLotto
             * @instance
             */
            LhcHisLotto.prototype.fit3 = "";

            /**
             * LhcHisLotto season.
             * @member {string} season
             * @memberof protobuf.response.LhcHisLotto
             * @instance
             */
            LhcHisLotto.prototype.season = "";

            /**
             * LhcHisLotto dayNight.
             * @member {string} dayNight
             * @memberof protobuf.response.LhcHisLotto
             * @instance
             */
            LhcHisLotto.prototype.dayNight = "";

            /**
             * Creates a new LhcHisLotto instance using the specified properties.
             * @function create
             * @memberof protobuf.response.LhcHisLotto
             * @static
             * @param {protobuf.response.ILhcHisLotto=} [properties] Properties to set
             * @returns {protobuf.response.LhcHisLotto} LhcHisLotto instance
             */
            LhcHisLotto.create = function create(properties) {
                return new LhcHisLotto(properties);
            };

            /**
             * Encodes the specified LhcHisLotto message. Does not implicitly {@link protobuf.response.LhcHisLotto.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.LhcHisLotto
             * @static
             * @param {protobuf.response.ILhcHisLotto} message LhcHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LhcHisLotto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.periods != null && Object.hasOwnProperty.call(message, "periods"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.periods);
                if (message.mark != null && Object.hasOwnProperty.call(message, "mark"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.mark);
                if (message.fit6 != null && Object.hasOwnProperty.call(message, "fit6"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.fit6);
                if (message.fit3 != null && Object.hasOwnProperty.call(message, "fit3"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.fit3);
                if (message.season != null && Object.hasOwnProperty.call(message, "season"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.season);
                if (message.dayNight != null && Object.hasOwnProperty.call(message, "dayNight"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.dayNight);
                return writer;
            };

            /**
             * Encodes the specified LhcHisLotto message, length delimited. Does not implicitly {@link protobuf.response.LhcHisLotto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.LhcHisLotto
             * @static
             * @param {protobuf.response.ILhcHisLotto} message LhcHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LhcHisLotto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LhcHisLotto message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.LhcHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.LhcHisLotto} LhcHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LhcHisLotto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.LhcHisLotto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.periods = reader.string();
                            break;
                        }
                    case 2: {
                            message.mark = reader.string();
                            break;
                        }
                    case 3: {
                            message.fit6 = reader.string();
                            break;
                        }
                    case 4: {
                            message.fit3 = reader.string();
                            break;
                        }
                    case 5: {
                            message.season = reader.string();
                            break;
                        }
                    case 6: {
                            message.dayNight = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LhcHisLotto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.LhcHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.LhcHisLotto} LhcHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LhcHisLotto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LhcHisLotto message.
             * @function verify
             * @memberof protobuf.response.LhcHisLotto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LhcHisLotto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.periods != null && message.hasOwnProperty("periods"))
                    if (!$util.isString(message.periods))
                        return "periods: string expected";
                if (message.mark != null && message.hasOwnProperty("mark"))
                    if (!$util.isString(message.mark))
                        return "mark: string expected";
                if (message.fit6 != null && message.hasOwnProperty("fit6"))
                    if (!$util.isString(message.fit6))
                        return "fit6: string expected";
                if (message.fit3 != null && message.hasOwnProperty("fit3"))
                    if (!$util.isString(message.fit3))
                        return "fit3: string expected";
                if (message.season != null && message.hasOwnProperty("season"))
                    if (!$util.isString(message.season))
                        return "season: string expected";
                if (message.dayNight != null && message.hasOwnProperty("dayNight"))
                    if (!$util.isString(message.dayNight))
                        return "dayNight: string expected";
                return null;
            };

            /**
             * Creates a LhcHisLotto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.LhcHisLotto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.LhcHisLotto} LhcHisLotto
             */
            LhcHisLotto.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.LhcHisLotto)
                    return object;
                let message = new $root.protobuf.response.LhcHisLotto();
                if (object.periods != null)
                    message.periods = String(object.periods);
                if (object.mark != null)
                    message.mark = String(object.mark);
                if (object.fit6 != null)
                    message.fit6 = String(object.fit6);
                if (object.fit3 != null)
                    message.fit3 = String(object.fit3);
                if (object.season != null)
                    message.season = String(object.season);
                if (object.dayNight != null)
                    message.dayNight = String(object.dayNight);
                return message;
            };

            /**
             * Creates a plain object from a LhcHisLotto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.LhcHisLotto
             * @static
             * @param {protobuf.response.LhcHisLotto} message LhcHisLotto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LhcHisLotto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.periods = "";
                    object.mark = "";
                    object.fit6 = "";
                    object.fit3 = "";
                    object.season = "";
                    object.dayNight = "";
                }
                if (message.periods != null && message.hasOwnProperty("periods"))
                    object.periods = message.periods;
                if (message.mark != null && message.hasOwnProperty("mark"))
                    object.mark = message.mark;
                if (message.fit6 != null && message.hasOwnProperty("fit6"))
                    object.fit6 = message.fit6;
                if (message.fit3 != null && message.hasOwnProperty("fit3"))
                    object.fit3 = message.fit3;
                if (message.season != null && message.hasOwnProperty("season"))
                    object.season = message.season;
                if (message.dayNight != null && message.hasOwnProperty("dayNight"))
                    object.dayNight = message.dayNight;
                return object;
            };

            /**
             * Converts this LhcHisLotto to JSON.
             * @function toJSON
             * @memberof protobuf.response.LhcHisLotto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LhcHisLotto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LhcHisLotto
             * @function getTypeUrl
             * @memberof protobuf.response.LhcHisLotto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LhcHisLotto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.LhcHisLotto";
            };

            return LhcHisLotto;
        })();

        response.XzcHisLotto = (function() {

            /**
             * Properties of a XzcHisLotto.
             * @memberof protobuf.response
             * @interface IXzcHisLotto
             * @property {string|null} [periods] XzcHisLotto periods
             * @property {string|null} [zodiacs] XzcHisLotto zodiacs
             * @property {string|null} [element] XzcHisLotto element
             * @property {string|null} [quality] XzcHisLotto quality
             * @property {string|null} [polarity] XzcHisLotto polarity
             */

            /**
             * Constructs a new XzcHisLotto.
             * @memberof protobuf.response
             * @classdesc Represents a XzcHisLotto.
             * @implements IXzcHisLotto
             * @constructor
             * @param {protobuf.response.IXzcHisLotto=} [properties] Properties to set
             */
            function XzcHisLotto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * XzcHisLotto periods.
             * @member {string} periods
             * @memberof protobuf.response.XzcHisLotto
             * @instance
             */
            XzcHisLotto.prototype.periods = "";

            /**
             * XzcHisLotto zodiacs.
             * @member {string} zodiacs
             * @memberof protobuf.response.XzcHisLotto
             * @instance
             */
            XzcHisLotto.prototype.zodiacs = "";

            /**
             * XzcHisLotto element.
             * @member {string} element
             * @memberof protobuf.response.XzcHisLotto
             * @instance
             */
            XzcHisLotto.prototype.element = "";

            /**
             * XzcHisLotto quality.
             * @member {string} quality
             * @memberof protobuf.response.XzcHisLotto
             * @instance
             */
            XzcHisLotto.prototype.quality = "";

            /**
             * XzcHisLotto polarity.
             * @member {string} polarity
             * @memberof protobuf.response.XzcHisLotto
             * @instance
             */
            XzcHisLotto.prototype.polarity = "";

            /**
             * Creates a new XzcHisLotto instance using the specified properties.
             * @function create
             * @memberof protobuf.response.XzcHisLotto
             * @static
             * @param {protobuf.response.IXzcHisLotto=} [properties] Properties to set
             * @returns {protobuf.response.XzcHisLotto} XzcHisLotto instance
             */
            XzcHisLotto.create = function create(properties) {
                return new XzcHisLotto(properties);
            };

            /**
             * Encodes the specified XzcHisLotto message. Does not implicitly {@link protobuf.response.XzcHisLotto.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.XzcHisLotto
             * @static
             * @param {protobuf.response.IXzcHisLotto} message XzcHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            XzcHisLotto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.periods != null && Object.hasOwnProperty.call(message, "periods"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.periods);
                if (message.zodiacs != null && Object.hasOwnProperty.call(message, "zodiacs"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.zodiacs);
                if (message.element != null && Object.hasOwnProperty.call(message, "element"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.element);
                if (message.quality != null && Object.hasOwnProperty.call(message, "quality"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.quality);
                if (message.polarity != null && Object.hasOwnProperty.call(message, "polarity"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.polarity);
                return writer;
            };

            /**
             * Encodes the specified XzcHisLotto message, length delimited. Does not implicitly {@link protobuf.response.XzcHisLotto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.XzcHisLotto
             * @static
             * @param {protobuf.response.IXzcHisLotto} message XzcHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            XzcHisLotto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a XzcHisLotto message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.XzcHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.XzcHisLotto} XzcHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            XzcHisLotto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.XzcHisLotto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.periods = reader.string();
                            break;
                        }
                    case 2: {
                            message.zodiacs = reader.string();
                            break;
                        }
                    case 3: {
                            message.element = reader.string();
                            break;
                        }
                    case 4: {
                            message.quality = reader.string();
                            break;
                        }
                    case 5: {
                            message.polarity = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a XzcHisLotto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.XzcHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.XzcHisLotto} XzcHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            XzcHisLotto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a XzcHisLotto message.
             * @function verify
             * @memberof protobuf.response.XzcHisLotto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            XzcHisLotto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.periods != null && message.hasOwnProperty("periods"))
                    if (!$util.isString(message.periods))
                        return "periods: string expected";
                if (message.zodiacs != null && message.hasOwnProperty("zodiacs"))
                    if (!$util.isString(message.zodiacs))
                        return "zodiacs: string expected";
                if (message.element != null && message.hasOwnProperty("element"))
                    if (!$util.isString(message.element))
                        return "element: string expected";
                if (message.quality != null && message.hasOwnProperty("quality"))
                    if (!$util.isString(message.quality))
                        return "quality: string expected";
                if (message.polarity != null && message.hasOwnProperty("polarity"))
                    if (!$util.isString(message.polarity))
                        return "polarity: string expected";
                return null;
            };

            /**
             * Creates a XzcHisLotto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.XzcHisLotto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.XzcHisLotto} XzcHisLotto
             */
            XzcHisLotto.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.XzcHisLotto)
                    return object;
                let message = new $root.protobuf.response.XzcHisLotto();
                if (object.periods != null)
                    message.periods = String(object.periods);
                if (object.zodiacs != null)
                    message.zodiacs = String(object.zodiacs);
                if (object.element != null)
                    message.element = String(object.element);
                if (object.quality != null)
                    message.quality = String(object.quality);
                if (object.polarity != null)
                    message.polarity = String(object.polarity);
                return message;
            };

            /**
             * Creates a plain object from a XzcHisLotto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.XzcHisLotto
             * @static
             * @param {protobuf.response.XzcHisLotto} message XzcHisLotto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            XzcHisLotto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.periods = "";
                    object.zodiacs = "";
                    object.element = "";
                    object.quality = "";
                    object.polarity = "";
                }
                if (message.periods != null && message.hasOwnProperty("periods"))
                    object.periods = message.periods;
                if (message.zodiacs != null && message.hasOwnProperty("zodiacs"))
                    object.zodiacs = message.zodiacs;
                if (message.element != null && message.hasOwnProperty("element"))
                    object.element = message.element;
                if (message.quality != null && message.hasOwnProperty("quality"))
                    object.quality = message.quality;
                if (message.polarity != null && message.hasOwnProperty("polarity"))
                    object.polarity = message.polarity;
                return object;
            };

            /**
             * Converts this XzcHisLotto to JSON.
             * @function toJSON
             * @memberof protobuf.response.XzcHisLotto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            XzcHisLotto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for XzcHisLotto
             * @function getTypeUrl
             * @memberof protobuf.response.XzcHisLotto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            XzcHisLotto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.XzcHisLotto";
            };

            return XzcHisLotto;
        })();

        response.Fc3dHisLotto = (function() {

            /**
             * Properties of a Fc3dHisLotto.
             * @memberof protobuf.response
             * @interface IFc3dHisLotto
             * @property {string|null} [periods] Fc3dHisLotto periods
             * @property {string|null} [number] Fc3dHisLotto number
             * @property {number|null} [sum] Fc3dHisLotto sum
             * @property {string|null} [smallBig] Fc3dHisLotto smallBig
             * @property {string|null} [oddEven] Fc3dHisLotto oddEven
             */

            /**
             * Constructs a new Fc3dHisLotto.
             * @memberof protobuf.response
             * @classdesc Represents a Fc3dHisLotto.
             * @implements IFc3dHisLotto
             * @constructor
             * @param {protobuf.response.IFc3dHisLotto=} [properties] Properties to set
             */
            function Fc3dHisLotto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Fc3dHisLotto periods.
             * @member {string} periods
             * @memberof protobuf.response.Fc3dHisLotto
             * @instance
             */
            Fc3dHisLotto.prototype.periods = "";

            /**
             * Fc3dHisLotto number.
             * @member {string} number
             * @memberof protobuf.response.Fc3dHisLotto
             * @instance
             */
            Fc3dHisLotto.prototype.number = "";

            /**
             * Fc3dHisLotto sum.
             * @member {number} sum
             * @memberof protobuf.response.Fc3dHisLotto
             * @instance
             */
            Fc3dHisLotto.prototype.sum = 0;

            /**
             * Fc3dHisLotto smallBig.
             * @member {string} smallBig
             * @memberof protobuf.response.Fc3dHisLotto
             * @instance
             */
            Fc3dHisLotto.prototype.smallBig = "";

            /**
             * Fc3dHisLotto oddEven.
             * @member {string} oddEven
             * @memberof protobuf.response.Fc3dHisLotto
             * @instance
             */
            Fc3dHisLotto.prototype.oddEven = "";

            /**
             * Creates a new Fc3dHisLotto instance using the specified properties.
             * @function create
             * @memberof protobuf.response.Fc3dHisLotto
             * @static
             * @param {protobuf.response.IFc3dHisLotto=} [properties] Properties to set
             * @returns {protobuf.response.Fc3dHisLotto} Fc3dHisLotto instance
             */
            Fc3dHisLotto.create = function create(properties) {
                return new Fc3dHisLotto(properties);
            };

            /**
             * Encodes the specified Fc3dHisLotto message. Does not implicitly {@link protobuf.response.Fc3dHisLotto.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.Fc3dHisLotto
             * @static
             * @param {protobuf.response.IFc3dHisLotto} message Fc3dHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Fc3dHisLotto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.periods != null && Object.hasOwnProperty.call(message, "periods"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.periods);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.number);
                if (message.sum != null && Object.hasOwnProperty.call(message, "sum"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sum);
                if (message.smallBig != null && Object.hasOwnProperty.call(message, "smallBig"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.smallBig);
                if (message.oddEven != null && Object.hasOwnProperty.call(message, "oddEven"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.oddEven);
                return writer;
            };

            /**
             * Encodes the specified Fc3dHisLotto message, length delimited. Does not implicitly {@link protobuf.response.Fc3dHisLotto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.Fc3dHisLotto
             * @static
             * @param {protobuf.response.IFc3dHisLotto} message Fc3dHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Fc3dHisLotto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Fc3dHisLotto message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.Fc3dHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.Fc3dHisLotto} Fc3dHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Fc3dHisLotto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.Fc3dHisLotto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.periods = reader.string();
                            break;
                        }
                    case 2: {
                            message.number = reader.string();
                            break;
                        }
                    case 3: {
                            message.sum = reader.int32();
                            break;
                        }
                    case 4: {
                            message.smallBig = reader.string();
                            break;
                        }
                    case 5: {
                            message.oddEven = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Fc3dHisLotto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.Fc3dHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.Fc3dHisLotto} Fc3dHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Fc3dHisLotto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Fc3dHisLotto message.
             * @function verify
             * @memberof protobuf.response.Fc3dHisLotto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Fc3dHisLotto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.periods != null && message.hasOwnProperty("periods"))
                    if (!$util.isString(message.periods))
                        return "periods: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isString(message.number))
                        return "number: string expected";
                if (message.sum != null && message.hasOwnProperty("sum"))
                    if (!$util.isInteger(message.sum))
                        return "sum: integer expected";
                if (message.smallBig != null && message.hasOwnProperty("smallBig"))
                    if (!$util.isString(message.smallBig))
                        return "smallBig: string expected";
                if (message.oddEven != null && message.hasOwnProperty("oddEven"))
                    if (!$util.isString(message.oddEven))
                        return "oddEven: string expected";
                return null;
            };

            /**
             * Creates a Fc3dHisLotto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.Fc3dHisLotto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.Fc3dHisLotto} Fc3dHisLotto
             */
            Fc3dHisLotto.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.Fc3dHisLotto)
                    return object;
                let message = new $root.protobuf.response.Fc3dHisLotto();
                if (object.periods != null)
                    message.periods = String(object.periods);
                if (object.number != null)
                    message.number = String(object.number);
                if (object.sum != null)
                    message.sum = object.sum | 0;
                if (object.smallBig != null)
                    message.smallBig = String(object.smallBig);
                if (object.oddEven != null)
                    message.oddEven = String(object.oddEven);
                return message;
            };

            /**
             * Creates a plain object from a Fc3dHisLotto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.Fc3dHisLotto
             * @static
             * @param {protobuf.response.Fc3dHisLotto} message Fc3dHisLotto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Fc3dHisLotto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.periods = "";
                    object.number = "";
                    object.sum = 0;
                    object.smallBig = "";
                    object.oddEven = "";
                }
                if (message.periods != null && message.hasOwnProperty("periods"))
                    object.periods = message.periods;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.sum != null && message.hasOwnProperty("sum"))
                    object.sum = message.sum;
                if (message.smallBig != null && message.hasOwnProperty("smallBig"))
                    object.smallBig = message.smallBig;
                if (message.oddEven != null && message.hasOwnProperty("oddEven"))
                    object.oddEven = message.oddEven;
                return object;
            };

            /**
             * Converts this Fc3dHisLotto to JSON.
             * @function toJSON
             * @memberof protobuf.response.Fc3dHisLotto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Fc3dHisLotto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Fc3dHisLotto
             * @function getTypeUrl
             * @memberof protobuf.response.Fc3dHisLotto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Fc3dHisLotto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.Fc3dHisLotto";
            };

            return Fc3dHisLotto;
        })();

        response.SscHisLotto = (function() {

            /**
             * Properties of a SscHisLotto.
             * @memberof protobuf.response
             * @interface ISscHisLotto
             * @property {string|null} [periods] SscHisLotto periods
             * @property {string|null} [number] SscHisLotto number
             * @property {number|null} [sum2] SscHisLotto sum2
             * @property {number|null} [sum3] SscHisLotto sum3
             * @property {string|null} [smallBig] SscHisLotto smallBig
             * @property {string|null} [oddEven] SscHisLotto oddEven
             */

            /**
             * Constructs a new SscHisLotto.
             * @memberof protobuf.response
             * @classdesc Represents a SscHisLotto.
             * @implements ISscHisLotto
             * @constructor
             * @param {protobuf.response.ISscHisLotto=} [properties] Properties to set
             */
            function SscHisLotto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SscHisLotto periods.
             * @member {string} periods
             * @memberof protobuf.response.SscHisLotto
             * @instance
             */
            SscHisLotto.prototype.periods = "";

            /**
             * SscHisLotto number.
             * @member {string} number
             * @memberof protobuf.response.SscHisLotto
             * @instance
             */
            SscHisLotto.prototype.number = "";

            /**
             * SscHisLotto sum2.
             * @member {number} sum2
             * @memberof protobuf.response.SscHisLotto
             * @instance
             */
            SscHisLotto.prototype.sum2 = 0;

            /**
             * SscHisLotto sum3.
             * @member {number} sum3
             * @memberof protobuf.response.SscHisLotto
             * @instance
             */
            SscHisLotto.prototype.sum3 = 0;

            /**
             * SscHisLotto smallBig.
             * @member {string} smallBig
             * @memberof protobuf.response.SscHisLotto
             * @instance
             */
            SscHisLotto.prototype.smallBig = "";

            /**
             * SscHisLotto oddEven.
             * @member {string} oddEven
             * @memberof protobuf.response.SscHisLotto
             * @instance
             */
            SscHisLotto.prototype.oddEven = "";

            /**
             * Creates a new SscHisLotto instance using the specified properties.
             * @function create
             * @memberof protobuf.response.SscHisLotto
             * @static
             * @param {protobuf.response.ISscHisLotto=} [properties] Properties to set
             * @returns {protobuf.response.SscHisLotto} SscHisLotto instance
             */
            SscHisLotto.create = function create(properties) {
                return new SscHisLotto(properties);
            };

            /**
             * Encodes the specified SscHisLotto message. Does not implicitly {@link protobuf.response.SscHisLotto.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.SscHisLotto
             * @static
             * @param {protobuf.response.ISscHisLotto} message SscHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SscHisLotto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.periods != null && Object.hasOwnProperty.call(message, "periods"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.periods);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.number);
                if (message.sum2 != null && Object.hasOwnProperty.call(message, "sum2"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sum2);
                if (message.sum3 != null && Object.hasOwnProperty.call(message, "sum3"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sum3);
                if (message.smallBig != null && Object.hasOwnProperty.call(message, "smallBig"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.smallBig);
                if (message.oddEven != null && Object.hasOwnProperty.call(message, "oddEven"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.oddEven);
                return writer;
            };

            /**
             * Encodes the specified SscHisLotto message, length delimited. Does not implicitly {@link protobuf.response.SscHisLotto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.SscHisLotto
             * @static
             * @param {protobuf.response.ISscHisLotto} message SscHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SscHisLotto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SscHisLotto message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.SscHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.SscHisLotto} SscHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SscHisLotto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.SscHisLotto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.periods = reader.string();
                            break;
                        }
                    case 2: {
                            message.number = reader.string();
                            break;
                        }
                    case 3: {
                            message.sum2 = reader.int32();
                            break;
                        }
                    case 4: {
                            message.sum3 = reader.int32();
                            break;
                        }
                    case 5: {
                            message.smallBig = reader.string();
                            break;
                        }
                    case 6: {
                            message.oddEven = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SscHisLotto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.SscHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.SscHisLotto} SscHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SscHisLotto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SscHisLotto message.
             * @function verify
             * @memberof protobuf.response.SscHisLotto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SscHisLotto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.periods != null && message.hasOwnProperty("periods"))
                    if (!$util.isString(message.periods))
                        return "periods: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isString(message.number))
                        return "number: string expected";
                if (message.sum2 != null && message.hasOwnProperty("sum2"))
                    if (!$util.isInteger(message.sum2))
                        return "sum2: integer expected";
                if (message.sum3 != null && message.hasOwnProperty("sum3"))
                    if (!$util.isInteger(message.sum3))
                        return "sum3: integer expected";
                if (message.smallBig != null && message.hasOwnProperty("smallBig"))
                    if (!$util.isString(message.smallBig))
                        return "smallBig: string expected";
                if (message.oddEven != null && message.hasOwnProperty("oddEven"))
                    if (!$util.isString(message.oddEven))
                        return "oddEven: string expected";
                return null;
            };

            /**
             * Creates a SscHisLotto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.SscHisLotto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.SscHisLotto} SscHisLotto
             */
            SscHisLotto.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.SscHisLotto)
                    return object;
                let message = new $root.protobuf.response.SscHisLotto();
                if (object.periods != null)
                    message.periods = String(object.periods);
                if (object.number != null)
                    message.number = String(object.number);
                if (object.sum2 != null)
                    message.sum2 = object.sum2 | 0;
                if (object.sum3 != null)
                    message.sum3 = object.sum3 | 0;
                if (object.smallBig != null)
                    message.smallBig = String(object.smallBig);
                if (object.oddEven != null)
                    message.oddEven = String(object.oddEven);
                return message;
            };

            /**
             * Creates a plain object from a SscHisLotto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.SscHisLotto
             * @static
             * @param {protobuf.response.SscHisLotto} message SscHisLotto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SscHisLotto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.periods = "";
                    object.number = "";
                    object.sum2 = 0;
                    object.sum3 = 0;
                    object.smallBig = "";
                    object.oddEven = "";
                }
                if (message.periods != null && message.hasOwnProperty("periods"))
                    object.periods = message.periods;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.sum2 != null && message.hasOwnProperty("sum2"))
                    object.sum2 = message.sum2;
                if (message.sum3 != null && message.hasOwnProperty("sum3"))
                    object.sum3 = message.sum3;
                if (message.smallBig != null && message.hasOwnProperty("smallBig"))
                    object.smallBig = message.smallBig;
                if (message.oddEven != null && message.hasOwnProperty("oddEven"))
                    object.oddEven = message.oddEven;
                return object;
            };

            /**
             * Converts this SscHisLotto to JSON.
             * @function toJSON
             * @memberof protobuf.response.SscHisLotto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SscHisLotto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SscHisLotto
             * @function getTypeUrl
             * @memberof protobuf.response.SscHisLotto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SscHisLotto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.SscHisLotto";
            };

            return SscHisLotto;
        })();

        response.SlotsHisLotto = (function() {

            /**
             * Properties of a SlotsHisLotto.
             * @memberof protobuf.response
             * @interface ISlotsHisLotto
             * @property {string|null} [periods] SlotsHisLotto periods
             * @property {string|null} [top] SlotsHisLotto top
             * @property {string|null} [bottom] SlotsHisLotto bottom
             * @property {string|null} [middle] SlotsHisLotto middle
             * @property {number|null} [bonus1_2] SlotsHisLotto bonus1_2
             * @property {number|null} [bonus1_3] SlotsHisLotto bonus1_3
             * @property {number|null} [bonus3_2] SlotsHisLotto bonus3_2
             * @property {number|null} [bonus3_3] SlotsHisLotto bonus3_3
             * @property {number|null} [bonus5_2] SlotsHisLotto bonus5_2
             * @property {number|null} [bonus5_3] SlotsHisLotto bonus5_3
             */

            /**
             * Constructs a new SlotsHisLotto.
             * @memberof protobuf.response
             * @classdesc Represents a SlotsHisLotto.
             * @implements ISlotsHisLotto
             * @constructor
             * @param {protobuf.response.ISlotsHisLotto=} [properties] Properties to set
             */
            function SlotsHisLotto(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SlotsHisLotto periods.
             * @member {string} periods
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             */
            SlotsHisLotto.prototype.periods = "";

            /**
             * SlotsHisLotto top.
             * @member {string} top
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             */
            SlotsHisLotto.prototype.top = "";

            /**
             * SlotsHisLotto bottom.
             * @member {string} bottom
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             */
            SlotsHisLotto.prototype.bottom = "";

            /**
             * SlotsHisLotto middle.
             * @member {string} middle
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             */
            SlotsHisLotto.prototype.middle = "";

            /**
             * SlotsHisLotto bonus1_2.
             * @member {number} bonus1_2
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             */
            SlotsHisLotto.prototype.bonus1_2 = 0;

            /**
             * SlotsHisLotto bonus1_3.
             * @member {number} bonus1_3
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             */
            SlotsHisLotto.prototype.bonus1_3 = 0;

            /**
             * SlotsHisLotto bonus3_2.
             * @member {number} bonus3_2
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             */
            SlotsHisLotto.prototype.bonus3_2 = 0;

            /**
             * SlotsHisLotto bonus3_3.
             * @member {number} bonus3_3
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             */
            SlotsHisLotto.prototype.bonus3_3 = 0;

            /**
             * SlotsHisLotto bonus5_2.
             * @member {number} bonus5_2
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             */
            SlotsHisLotto.prototype.bonus5_2 = 0;

            /**
             * SlotsHisLotto bonus5_3.
             * @member {number} bonus5_3
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             */
            SlotsHisLotto.prototype.bonus5_3 = 0;

            /**
             * Creates a new SlotsHisLotto instance using the specified properties.
             * @function create
             * @memberof protobuf.response.SlotsHisLotto
             * @static
             * @param {protobuf.response.ISlotsHisLotto=} [properties] Properties to set
             * @returns {protobuf.response.SlotsHisLotto} SlotsHisLotto instance
             */
            SlotsHisLotto.create = function create(properties) {
                return new SlotsHisLotto(properties);
            };

            /**
             * Encodes the specified SlotsHisLotto message. Does not implicitly {@link protobuf.response.SlotsHisLotto.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.SlotsHisLotto
             * @static
             * @param {protobuf.response.ISlotsHisLotto} message SlotsHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SlotsHisLotto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.periods != null && Object.hasOwnProperty.call(message, "periods"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.periods);
                if (message.top != null && Object.hasOwnProperty.call(message, "top"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.top);
                if (message.bottom != null && Object.hasOwnProperty.call(message, "bottom"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.bottom);
                if (message.middle != null && Object.hasOwnProperty.call(message, "middle"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.middle);
                if (message.bonus1_2 != null && Object.hasOwnProperty.call(message, "bonus1_2"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.bonus1_2);
                if (message.bonus1_3 != null && Object.hasOwnProperty.call(message, "bonus1_3"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.bonus1_3);
                if (message.bonus3_2 != null && Object.hasOwnProperty.call(message, "bonus3_2"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.bonus3_2);
                if (message.bonus3_3 != null && Object.hasOwnProperty.call(message, "bonus3_3"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.bonus3_3);
                if (message.bonus5_2 != null && Object.hasOwnProperty.call(message, "bonus5_2"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.bonus5_2);
                if (message.bonus5_3 != null && Object.hasOwnProperty.call(message, "bonus5_3"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.bonus5_3);
                return writer;
            };

            /**
             * Encodes the specified SlotsHisLotto message, length delimited. Does not implicitly {@link protobuf.response.SlotsHisLotto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.SlotsHisLotto
             * @static
             * @param {protobuf.response.ISlotsHisLotto} message SlotsHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SlotsHisLotto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SlotsHisLotto message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.SlotsHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.SlotsHisLotto} SlotsHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SlotsHisLotto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.SlotsHisLotto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.periods = reader.string();
                            break;
                        }
                    case 2: {
                            message.top = reader.string();
                            break;
                        }
                    case 3: {
                            message.bottom = reader.string();
                            break;
                        }
                    case 4: {
                            message.middle = reader.string();
                            break;
                        }
                    case 5: {
                            message.bonus1_2 = reader.int32();
                            break;
                        }
                    case 6: {
                            message.bonus1_3 = reader.int32();
                            break;
                        }
                    case 7: {
                            message.bonus3_2 = reader.int32();
                            break;
                        }
                    case 8: {
                            message.bonus3_3 = reader.int32();
                            break;
                        }
                    case 9: {
                            message.bonus5_2 = reader.int32();
                            break;
                        }
                    case 10: {
                            message.bonus5_3 = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SlotsHisLotto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.SlotsHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.SlotsHisLotto} SlotsHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SlotsHisLotto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SlotsHisLotto message.
             * @function verify
             * @memberof protobuf.response.SlotsHisLotto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SlotsHisLotto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.periods != null && message.hasOwnProperty("periods"))
                    if (!$util.isString(message.periods))
                        return "periods: string expected";
                if (message.top != null && message.hasOwnProperty("top"))
                    if (!$util.isString(message.top))
                        return "top: string expected";
                if (message.bottom != null && message.hasOwnProperty("bottom"))
                    if (!$util.isString(message.bottom))
                        return "bottom: string expected";
                if (message.middle != null && message.hasOwnProperty("middle"))
                    if (!$util.isString(message.middle))
                        return "middle: string expected";
                if (message.bonus1_2 != null && message.hasOwnProperty("bonus1_2"))
                    if (!$util.isInteger(message.bonus1_2))
                        return "bonus1_2: integer expected";
                if (message.bonus1_3 != null && message.hasOwnProperty("bonus1_3"))
                    if (!$util.isInteger(message.bonus1_3))
                        return "bonus1_3: integer expected";
                if (message.bonus3_2 != null && message.hasOwnProperty("bonus3_2"))
                    if (!$util.isInteger(message.bonus3_2))
                        return "bonus3_2: integer expected";
                if (message.bonus3_3 != null && message.hasOwnProperty("bonus3_3"))
                    if (!$util.isInteger(message.bonus3_3))
                        return "bonus3_3: integer expected";
                if (message.bonus5_2 != null && message.hasOwnProperty("bonus5_2"))
                    if (!$util.isInteger(message.bonus5_2))
                        return "bonus5_2: integer expected";
                if (message.bonus5_3 != null && message.hasOwnProperty("bonus5_3"))
                    if (!$util.isInteger(message.bonus5_3))
                        return "bonus5_3: integer expected";
                return null;
            };

            /**
             * Creates a SlotsHisLotto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.SlotsHisLotto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.SlotsHisLotto} SlotsHisLotto
             */
            SlotsHisLotto.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.SlotsHisLotto)
                    return object;
                let message = new $root.protobuf.response.SlotsHisLotto();
                if (object.periods != null)
                    message.periods = String(object.periods);
                if (object.top != null)
                    message.top = String(object.top);
                if (object.bottom != null)
                    message.bottom = String(object.bottom);
                if (object.middle != null)
                    message.middle = String(object.middle);
                if (object.bonus1_2 != null)
                    message.bonus1_2 = object.bonus1_2 | 0;
                if (object.bonus1_3 != null)
                    message.bonus1_3 = object.bonus1_3 | 0;
                if (object.bonus3_2 != null)
                    message.bonus3_2 = object.bonus3_2 | 0;
                if (object.bonus3_3 != null)
                    message.bonus3_3 = object.bonus3_3 | 0;
                if (object.bonus5_2 != null)
                    message.bonus5_2 = object.bonus5_2 | 0;
                if (object.bonus5_3 != null)
                    message.bonus5_3 = object.bonus5_3 | 0;
                return message;
            };

            /**
             * Creates a plain object from a SlotsHisLotto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.SlotsHisLotto
             * @static
             * @param {protobuf.response.SlotsHisLotto} message SlotsHisLotto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SlotsHisLotto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.periods = "";
                    object.top = "";
                    object.bottom = "";
                    object.middle = "";
                    object.bonus1_2 = 0;
                    object.bonus1_3 = 0;
                    object.bonus3_2 = 0;
                    object.bonus3_3 = 0;
                    object.bonus5_2 = 0;
                    object.bonus5_3 = 0;
                }
                if (message.periods != null && message.hasOwnProperty("periods"))
                    object.periods = message.periods;
                if (message.top != null && message.hasOwnProperty("top"))
                    object.top = message.top;
                if (message.bottom != null && message.hasOwnProperty("bottom"))
                    object.bottom = message.bottom;
                if (message.middle != null && message.hasOwnProperty("middle"))
                    object.middle = message.middle;
                if (message.bonus1_2 != null && message.hasOwnProperty("bonus1_2"))
                    object.bonus1_2 = message.bonus1_2;
                if (message.bonus1_3 != null && message.hasOwnProperty("bonus1_3"))
                    object.bonus1_3 = message.bonus1_3;
                if (message.bonus3_2 != null && message.hasOwnProperty("bonus3_2"))
                    object.bonus3_2 = message.bonus3_2;
                if (message.bonus3_3 != null && message.hasOwnProperty("bonus3_3"))
                    object.bonus3_3 = message.bonus3_3;
                if (message.bonus5_2 != null && message.hasOwnProperty("bonus5_2"))
                    object.bonus5_2 = message.bonus5_2;
                if (message.bonus5_3 != null && message.hasOwnProperty("bonus5_3"))
                    object.bonus5_3 = message.bonus5_3;
                return object;
            };

            /**
             * Converts this SlotsHisLotto to JSON.
             * @function toJSON
             * @memberof protobuf.response.SlotsHisLotto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SlotsHisLotto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SlotsHisLotto
             * @function getTypeUrl
             * @memberof protobuf.response.SlotsHisLotto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SlotsHisLotto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.SlotsHisLotto";
            };

            return SlotsHisLotto;
        })();

        response.Poker = (function() {

            /**
             * Properties of a Poker.
             * @memberof protobuf.response
             * @interface IPoker
             * @property {protobuf.response.Suit|null} [suit] Poker suit
             * @property {string|null} [value] Poker value
             */

            /**
             * Constructs a new Poker.
             * @memberof protobuf.response
             * @classdesc Represents a Poker.
             * @implements IPoker
             * @constructor
             * @param {protobuf.response.IPoker=} [properties] Properties to set
             */
            function Poker(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Poker suit.
             * @member {protobuf.response.Suit} suit
             * @memberof protobuf.response.Poker
             * @instance
             */
            Poker.prototype.suit = 0;

            /**
             * Poker value.
             * @member {string} value
             * @memberof protobuf.response.Poker
             * @instance
             */
            Poker.prototype.value = "";

            /**
             * Creates a new Poker instance using the specified properties.
             * @function create
             * @memberof protobuf.response.Poker
             * @static
             * @param {protobuf.response.IPoker=} [properties] Properties to set
             * @returns {protobuf.response.Poker} Poker instance
             */
            Poker.create = function create(properties) {
                return new Poker(properties);
            };

            /**
             * Encodes the specified Poker message. Does not implicitly {@link protobuf.response.Poker.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.Poker
             * @static
             * @param {protobuf.response.IPoker} message Poker message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Poker.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.suit != null && Object.hasOwnProperty.call(message, "suit"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.suit);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                return writer;
            };

            /**
             * Encodes the specified Poker message, length delimited. Does not implicitly {@link protobuf.response.Poker.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.Poker
             * @static
             * @param {protobuf.response.IPoker} message Poker message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Poker.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Poker message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.Poker
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.Poker} Poker
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Poker.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.Poker();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.suit = reader.int32();
                            break;
                        }
                    case 2: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Poker message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.Poker
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.Poker} Poker
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Poker.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Poker message.
             * @function verify
             * @memberof protobuf.response.Poker
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Poker.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.suit != null && message.hasOwnProperty("suit"))
                    switch (message.suit) {
                    default:
                        return "suit: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                return null;
            };

            /**
             * Creates a Poker message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.Poker
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.Poker} Poker
             */
            Poker.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.Poker)
                    return object;
                let message = new $root.protobuf.response.Poker();
                switch (object.suit) {
                default:
                    if (typeof object.suit === "number") {
                        message.suit = object.suit;
                        break;
                    }
                    break;
                case "CLUBS":
                case 0:
                    message.suit = 0;
                    break;
                case "DIAMONDS":
                case 1:
                    message.suit = 1;
                    break;
                case "HEARTS":
                case 2:
                    message.suit = 2;
                    break;
                case "SPADES":
                case 3:
                    message.suit = 3;
                    break;
                }
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            /**
             * Creates a plain object from a Poker message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.Poker
             * @static
             * @param {protobuf.response.Poker} message Poker
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Poker.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.suit = options.enums === String ? "CLUBS" : 0;
                    object.value = "";
                }
                if (message.suit != null && message.hasOwnProperty("suit"))
                    object.suit = options.enums === String ? $root.protobuf.response.Suit[message.suit] === undefined ? message.suit : $root.protobuf.response.Suit[message.suit] : message.suit;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this Poker to JSON.
             * @function toJSON
             * @memberof protobuf.response.Poker
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Poker.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Poker
             * @function getTypeUrl
             * @memberof protobuf.response.Poker
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Poker.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.Poker";
            };

            return Poker;
        })();

        response.TexasHisLotto = (function() {

            /**
             * Properties of a TexasHisLotto.
             * @memberof protobuf.response
             * @interface ITexasHisLotto
             * @property {string|null} [periods] TexasHisLotto periods
             * @property {string|null} [whoWin] TexasHisLotto whoWin
             * @property {Array.<protobuf.response.IPoker>|null} [common] TexasHisLotto common
             * @property {Array.<protobuf.response.IPoker>|null} [player] TexasHisLotto player
             * @property {Array.<protobuf.response.IPoker>|null} [dealer] TexasHisLotto dealer
             * @property {Array.<protobuf.response.IPoker>|null} [playerComba] TexasHisLotto playerComba
             * @property {Array.<protobuf.response.IPoker>|null} [dealerComba] TexasHisLotto dealerComba
             * @property {string|null} [playerCombaName] TexasHisLotto playerCombaName
             * @property {string|null} [dealerCombaName] TexasHisLotto dealerCombaName
             */

            /**
             * Constructs a new TexasHisLotto.
             * @memberof protobuf.response
             * @classdesc Represents a TexasHisLotto.
             * @implements ITexasHisLotto
             * @constructor
             * @param {protobuf.response.ITexasHisLotto=} [properties] Properties to set
             */
            function TexasHisLotto(properties) {
                this.common = [];
                this.player = [];
                this.dealer = [];
                this.playerComba = [];
                this.dealerComba = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TexasHisLotto periods.
             * @member {string} periods
             * @memberof protobuf.response.TexasHisLotto
             * @instance
             */
            TexasHisLotto.prototype.periods = "";

            /**
             * TexasHisLotto whoWin.
             * @member {string} whoWin
             * @memberof protobuf.response.TexasHisLotto
             * @instance
             */
            TexasHisLotto.prototype.whoWin = "";

            /**
             * TexasHisLotto common.
             * @member {Array.<protobuf.response.IPoker>} common
             * @memberof protobuf.response.TexasHisLotto
             * @instance
             */
            TexasHisLotto.prototype.common = $util.emptyArray;

            /**
             * TexasHisLotto player.
             * @member {Array.<protobuf.response.IPoker>} player
             * @memberof protobuf.response.TexasHisLotto
             * @instance
             */
            TexasHisLotto.prototype.player = $util.emptyArray;

            /**
             * TexasHisLotto dealer.
             * @member {Array.<protobuf.response.IPoker>} dealer
             * @memberof protobuf.response.TexasHisLotto
             * @instance
             */
            TexasHisLotto.prototype.dealer = $util.emptyArray;

            /**
             * TexasHisLotto playerComba.
             * @member {Array.<protobuf.response.IPoker>} playerComba
             * @memberof protobuf.response.TexasHisLotto
             * @instance
             */
            TexasHisLotto.prototype.playerComba = $util.emptyArray;

            /**
             * TexasHisLotto dealerComba.
             * @member {Array.<protobuf.response.IPoker>} dealerComba
             * @memberof protobuf.response.TexasHisLotto
             * @instance
             */
            TexasHisLotto.prototype.dealerComba = $util.emptyArray;

            /**
             * TexasHisLotto playerCombaName.
             * @member {string} playerCombaName
             * @memberof protobuf.response.TexasHisLotto
             * @instance
             */
            TexasHisLotto.prototype.playerCombaName = "";

            /**
             * TexasHisLotto dealerCombaName.
             * @member {string} dealerCombaName
             * @memberof protobuf.response.TexasHisLotto
             * @instance
             */
            TexasHisLotto.prototype.dealerCombaName = "";

            /**
             * Creates a new TexasHisLotto instance using the specified properties.
             * @function create
             * @memberof protobuf.response.TexasHisLotto
             * @static
             * @param {protobuf.response.ITexasHisLotto=} [properties] Properties to set
             * @returns {protobuf.response.TexasHisLotto} TexasHisLotto instance
             */
            TexasHisLotto.create = function create(properties) {
                return new TexasHisLotto(properties);
            };

            /**
             * Encodes the specified TexasHisLotto message. Does not implicitly {@link protobuf.response.TexasHisLotto.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.TexasHisLotto
             * @static
             * @param {protobuf.response.ITexasHisLotto} message TexasHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TexasHisLotto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.periods != null && Object.hasOwnProperty.call(message, "periods"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.periods);
                if (message.whoWin != null && Object.hasOwnProperty.call(message, "whoWin"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.whoWin);
                if (message.common != null && message.common.length)
                    for (let i = 0; i < message.common.length; ++i)
                        $root.protobuf.response.Poker.encode(message.common[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.player != null && message.player.length)
                    for (let i = 0; i < message.player.length; ++i)
                        $root.protobuf.response.Poker.encode(message.player[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.dealer != null && message.dealer.length)
                    for (let i = 0; i < message.dealer.length; ++i)
                        $root.protobuf.response.Poker.encode(message.dealer[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.playerComba != null && message.playerComba.length)
                    for (let i = 0; i < message.playerComba.length; ++i)
                        $root.protobuf.response.Poker.encode(message.playerComba[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.dealerComba != null && message.dealerComba.length)
                    for (let i = 0; i < message.dealerComba.length; ++i)
                        $root.protobuf.response.Poker.encode(message.dealerComba[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.playerCombaName != null && Object.hasOwnProperty.call(message, "playerCombaName"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.playerCombaName);
                if (message.dealerCombaName != null && Object.hasOwnProperty.call(message, "dealerCombaName"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.dealerCombaName);
                return writer;
            };

            /**
             * Encodes the specified TexasHisLotto message, length delimited. Does not implicitly {@link protobuf.response.TexasHisLotto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.TexasHisLotto
             * @static
             * @param {protobuf.response.ITexasHisLotto} message TexasHisLotto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TexasHisLotto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TexasHisLotto message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.TexasHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.TexasHisLotto} TexasHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TexasHisLotto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.TexasHisLotto();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.periods = reader.string();
                            break;
                        }
                    case 2: {
                            message.whoWin = reader.string();
                            break;
                        }
                    case 3: {
                            if (!(message.common && message.common.length))
                                message.common = [];
                            message.common.push($root.protobuf.response.Poker.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            if (!(message.player && message.player.length))
                                message.player = [];
                            message.player.push($root.protobuf.response.Poker.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.dealer && message.dealer.length))
                                message.dealer = [];
                            message.dealer.push($root.protobuf.response.Poker.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.playerComba && message.playerComba.length))
                                message.playerComba = [];
                            message.playerComba.push($root.protobuf.response.Poker.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            if (!(message.dealerComba && message.dealerComba.length))
                                message.dealerComba = [];
                            message.dealerComba.push($root.protobuf.response.Poker.decode(reader, reader.uint32()));
                            break;
                        }
                    case 8: {
                            message.playerCombaName = reader.string();
                            break;
                        }
                    case 9: {
                            message.dealerCombaName = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TexasHisLotto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.TexasHisLotto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.TexasHisLotto} TexasHisLotto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TexasHisLotto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TexasHisLotto message.
             * @function verify
             * @memberof protobuf.response.TexasHisLotto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TexasHisLotto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.periods != null && message.hasOwnProperty("periods"))
                    if (!$util.isString(message.periods))
                        return "periods: string expected";
                if (message.whoWin != null && message.hasOwnProperty("whoWin"))
                    if (!$util.isString(message.whoWin))
                        return "whoWin: string expected";
                if (message.common != null && message.hasOwnProperty("common")) {
                    if (!Array.isArray(message.common))
                        return "common: array expected";
                    for (let i = 0; i < message.common.length; ++i) {
                        let error = $root.protobuf.response.Poker.verify(message.common[i]);
                        if (error)
                            return "common." + error;
                    }
                }
                if (message.player != null && message.hasOwnProperty("player")) {
                    if (!Array.isArray(message.player))
                        return "player: array expected";
                    for (let i = 0; i < message.player.length; ++i) {
                        let error = $root.protobuf.response.Poker.verify(message.player[i]);
                        if (error)
                            return "player." + error;
                    }
                }
                if (message.dealer != null && message.hasOwnProperty("dealer")) {
                    if (!Array.isArray(message.dealer))
                        return "dealer: array expected";
                    for (let i = 0; i < message.dealer.length; ++i) {
                        let error = $root.protobuf.response.Poker.verify(message.dealer[i]);
                        if (error)
                            return "dealer." + error;
                    }
                }
                if (message.playerComba != null && message.hasOwnProperty("playerComba")) {
                    if (!Array.isArray(message.playerComba))
                        return "playerComba: array expected";
                    for (let i = 0; i < message.playerComba.length; ++i) {
                        let error = $root.protobuf.response.Poker.verify(message.playerComba[i]);
                        if (error)
                            return "playerComba." + error;
                    }
                }
                if (message.dealerComba != null && message.hasOwnProperty("dealerComba")) {
                    if (!Array.isArray(message.dealerComba))
                        return "dealerComba: array expected";
                    for (let i = 0; i < message.dealerComba.length; ++i) {
                        let error = $root.protobuf.response.Poker.verify(message.dealerComba[i]);
                        if (error)
                            return "dealerComba." + error;
                    }
                }
                if (message.playerCombaName != null && message.hasOwnProperty("playerCombaName"))
                    if (!$util.isString(message.playerCombaName))
                        return "playerCombaName: string expected";
                if (message.dealerCombaName != null && message.hasOwnProperty("dealerCombaName"))
                    if (!$util.isString(message.dealerCombaName))
                        return "dealerCombaName: string expected";
                return null;
            };

            /**
             * Creates a TexasHisLotto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.TexasHisLotto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.TexasHisLotto} TexasHisLotto
             */
            TexasHisLotto.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.TexasHisLotto)
                    return object;
                let message = new $root.protobuf.response.TexasHisLotto();
                if (object.periods != null)
                    message.periods = String(object.periods);
                if (object.whoWin != null)
                    message.whoWin = String(object.whoWin);
                if (object.common) {
                    if (!Array.isArray(object.common))
                        throw TypeError(".protobuf.response.TexasHisLotto.common: array expected");
                    message.common = [];
                    for (let i = 0; i < object.common.length; ++i) {
                        if (typeof object.common[i] !== "object")
                            throw TypeError(".protobuf.response.TexasHisLotto.common: object expected");
                        message.common[i] = $root.protobuf.response.Poker.fromObject(object.common[i]);
                    }
                }
                if (object.player) {
                    if (!Array.isArray(object.player))
                        throw TypeError(".protobuf.response.TexasHisLotto.player: array expected");
                    message.player = [];
                    for (let i = 0; i < object.player.length; ++i) {
                        if (typeof object.player[i] !== "object")
                            throw TypeError(".protobuf.response.TexasHisLotto.player: object expected");
                        message.player[i] = $root.protobuf.response.Poker.fromObject(object.player[i]);
                    }
                }
                if (object.dealer) {
                    if (!Array.isArray(object.dealer))
                        throw TypeError(".protobuf.response.TexasHisLotto.dealer: array expected");
                    message.dealer = [];
                    for (let i = 0; i < object.dealer.length; ++i) {
                        if (typeof object.dealer[i] !== "object")
                            throw TypeError(".protobuf.response.TexasHisLotto.dealer: object expected");
                        message.dealer[i] = $root.protobuf.response.Poker.fromObject(object.dealer[i]);
                    }
                }
                if (object.playerComba) {
                    if (!Array.isArray(object.playerComba))
                        throw TypeError(".protobuf.response.TexasHisLotto.playerComba: array expected");
                    message.playerComba = [];
                    for (let i = 0; i < object.playerComba.length; ++i) {
                        if (typeof object.playerComba[i] !== "object")
                            throw TypeError(".protobuf.response.TexasHisLotto.playerComba: object expected");
                        message.playerComba[i] = $root.protobuf.response.Poker.fromObject(object.playerComba[i]);
                    }
                }
                if (object.dealerComba) {
                    if (!Array.isArray(object.dealerComba))
                        throw TypeError(".protobuf.response.TexasHisLotto.dealerComba: array expected");
                    message.dealerComba = [];
                    for (let i = 0; i < object.dealerComba.length; ++i) {
                        if (typeof object.dealerComba[i] !== "object")
                            throw TypeError(".protobuf.response.TexasHisLotto.dealerComba: object expected");
                        message.dealerComba[i] = $root.protobuf.response.Poker.fromObject(object.dealerComba[i]);
                    }
                }
                if (object.playerCombaName != null)
                    message.playerCombaName = String(object.playerCombaName);
                if (object.dealerCombaName != null)
                    message.dealerCombaName = String(object.dealerCombaName);
                return message;
            };

            /**
             * Creates a plain object from a TexasHisLotto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.TexasHisLotto
             * @static
             * @param {protobuf.response.TexasHisLotto} message TexasHisLotto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TexasHisLotto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults) {
                    object.common = [];
                    object.player = [];
                    object.dealer = [];
                    object.playerComba = [];
                    object.dealerComba = [];
                }
                if (options.defaults) {
                    object.periods = "";
                    object.whoWin = "";
                    object.playerCombaName = "";
                    object.dealerCombaName = "";
                }
                if (message.periods != null && message.hasOwnProperty("periods"))
                    object.periods = message.periods;
                if (message.whoWin != null && message.hasOwnProperty("whoWin"))
                    object.whoWin = message.whoWin;
                if (message.common && message.common.length) {
                    object.common = [];
                    for (let j = 0; j < message.common.length; ++j)
                        object.common[j] = $root.protobuf.response.Poker.toObject(message.common[j], options);
                }
                if (message.player && message.player.length) {
                    object.player = [];
                    for (let j = 0; j < message.player.length; ++j)
                        object.player[j] = $root.protobuf.response.Poker.toObject(message.player[j], options);
                }
                if (message.dealer && message.dealer.length) {
                    object.dealer = [];
                    for (let j = 0; j < message.dealer.length; ++j)
                        object.dealer[j] = $root.protobuf.response.Poker.toObject(message.dealer[j], options);
                }
                if (message.playerComba && message.playerComba.length) {
                    object.playerComba = [];
                    for (let j = 0; j < message.playerComba.length; ++j)
                        object.playerComba[j] = $root.protobuf.response.Poker.toObject(message.playerComba[j], options);
                }
                if (message.dealerComba && message.dealerComba.length) {
                    object.dealerComba = [];
                    for (let j = 0; j < message.dealerComba.length; ++j)
                        object.dealerComba[j] = $root.protobuf.response.Poker.toObject(message.dealerComba[j], options);
                }
                if (message.playerCombaName != null && message.hasOwnProperty("playerCombaName"))
                    object.playerCombaName = message.playerCombaName;
                if (message.dealerCombaName != null && message.hasOwnProperty("dealerCombaName"))
                    object.dealerCombaName = message.dealerCombaName;
                return object;
            };

            /**
             * Converts this TexasHisLotto to JSON.
             * @function toJSON
             * @memberof protobuf.response.TexasHisLotto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TexasHisLotto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TexasHisLotto
             * @function getTypeUrl
             * @memberof protobuf.response.TexasHisLotto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TexasHisLotto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.TexasHisLotto";
            };

            return TexasHisLotto;
        })();

        response.HisOrderResponse = (function() {

            /**
             * Properties of a HisOrderResponse.
             * @memberof protobuf.response
             * @interface IHisOrderResponse
             * @property {Array.<protobuf.response.IHisOrder>|null} [records] HisOrderResponse records
             */

            /**
             * Constructs a new HisOrderResponse.
             * @memberof protobuf.response
             * @classdesc Represents a HisOrderResponse.
             * @implements IHisOrderResponse
             * @constructor
             * @param {protobuf.response.IHisOrderResponse=} [properties] Properties to set
             */
            function HisOrderResponse(properties) {
                this.records = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * HisOrderResponse records.
             * @member {Array.<protobuf.response.IHisOrder>} records
             * @memberof protobuf.response.HisOrderResponse
             * @instance
             */
            HisOrderResponse.prototype.records = $util.emptyArray;

            /**
             * Creates a new HisOrderResponse instance using the specified properties.
             * @function create
             * @memberof protobuf.response.HisOrderResponse
             * @static
             * @param {protobuf.response.IHisOrderResponse=} [properties] Properties to set
             * @returns {protobuf.response.HisOrderResponse} HisOrderResponse instance
             */
            HisOrderResponse.create = function create(properties) {
                return new HisOrderResponse(properties);
            };

            /**
             * Encodes the specified HisOrderResponse message. Does not implicitly {@link protobuf.response.HisOrderResponse.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.HisOrderResponse
             * @static
             * @param {protobuf.response.IHisOrderResponse} message HisOrderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HisOrderResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.records != null && message.records.length)
                    for (let i = 0; i < message.records.length; ++i)
                        $root.protobuf.response.HisOrder.encode(message.records[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified HisOrderResponse message, length delimited. Does not implicitly {@link protobuf.response.HisOrderResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.HisOrderResponse
             * @static
             * @param {protobuf.response.IHisOrderResponse} message HisOrderResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HisOrderResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a HisOrderResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.HisOrderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.HisOrderResponse} HisOrderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HisOrderResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.HisOrderResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.records && message.records.length))
                                message.records = [];
                            message.records.push($root.protobuf.response.HisOrder.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a HisOrderResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.HisOrderResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.HisOrderResponse} HisOrderResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HisOrderResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a HisOrderResponse message.
             * @function verify
             * @memberof protobuf.response.HisOrderResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HisOrderResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.records != null && message.hasOwnProperty("records")) {
                    if (!Array.isArray(message.records))
                        return "records: array expected";
                    for (let i = 0; i < message.records.length; ++i) {
                        let error = $root.protobuf.response.HisOrder.verify(message.records[i]);
                        if (error)
                            return "records." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a HisOrderResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.HisOrderResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.HisOrderResponse} HisOrderResponse
             */
            HisOrderResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.HisOrderResponse)
                    return object;
                let message = new $root.protobuf.response.HisOrderResponse();
                if (object.records) {
                    if (!Array.isArray(object.records))
                        throw TypeError(".protobuf.response.HisOrderResponse.records: array expected");
                    message.records = [];
                    for (let i = 0; i < object.records.length; ++i) {
                        if (typeof object.records[i] !== "object")
                            throw TypeError(".protobuf.response.HisOrderResponse.records: object expected");
                        message.records[i] = $root.protobuf.response.HisOrder.fromObject(object.records[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a HisOrderResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.HisOrderResponse
             * @static
             * @param {protobuf.response.HisOrderResponse} message HisOrderResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HisOrderResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.records = [];
                if (message.records && message.records.length) {
                    object.records = [];
                    for (let j = 0; j < message.records.length; ++j)
                        object.records[j] = $root.protobuf.response.HisOrder.toObject(message.records[j], options);
                }
                return object;
            };

            /**
             * Converts this HisOrderResponse to JSON.
             * @function toJSON
             * @memberof protobuf.response.HisOrderResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HisOrderResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for HisOrderResponse
             * @function getTypeUrl
             * @memberof protobuf.response.HisOrderResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            HisOrderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.HisOrderResponse";
            };

            return HisOrderResponse;
        })();

        response.HisOrder = (function() {

            /**
             * Properties of a HisOrder.
             * @memberof protobuf.response
             * @interface IHisOrder
             * @property {number|Long|null} [id] HisOrder id
             * @property {protobuf.common.Lottery|null} [lottery] HisOrder lottery
             * @property {string|null} [childGameType] HisOrder childGameType
             * @property {number|null} [D] HisOrder D
             * @property {boolean|null} [universal] HisOrder universal
             * @property {string|null} [buy] HisOrder buy
             * @property {number|null} [tickets] HisOrder tickets
             * @property {number|null} [quantity] HisOrder quantity
             * @property {number|null} [unitPrice] HisOrder unitPrice
             * @property {number|Long|null} [cost] HisOrder cost
             * @property {number|null} [hitPrizeMax] HisOrder hitPrizeMax
             * @property {string|null} [periods] HisOrder periods
             * @property {number|Long|null} [buyTime] HisOrder buyTime
             * @property {number|null} [uid] HisOrder uid
             * @property {number|null} [claim] HisOrder claim
             * @property {number|null} [hitPrize] HisOrder hitPrize
             * @property {number|null} [balanceAfter] HisOrder balanceAfter
             */

            /**
             * Constructs a new HisOrder.
             * @memberof protobuf.response
             * @classdesc Represents a HisOrder.
             * @implements IHisOrder
             * @constructor
             * @param {protobuf.response.IHisOrder=} [properties] Properties to set
             */
            function HisOrder(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * HisOrder id.
             * @member {number|Long} id
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * HisOrder lottery.
             * @member {protobuf.common.Lottery} lottery
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.lottery = 0;

            /**
             * HisOrder childGameType.
             * @member {string} childGameType
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.childGameType = "";

            /**
             * HisOrder D.
             * @member {number} D
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.D = 0;

            /**
             * HisOrder universal.
             * @member {boolean} universal
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.universal = false;

            /**
             * HisOrder buy.
             * @member {string} buy
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.buy = "";

            /**
             * HisOrder tickets.
             * @member {number} tickets
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.tickets = 0;

            /**
             * HisOrder quantity.
             * @member {number} quantity
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.quantity = 0;

            /**
             * HisOrder unitPrice.
             * @member {number} unitPrice
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.unitPrice = 0;

            /**
             * HisOrder cost.
             * @member {number|Long} cost
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.cost = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * HisOrder hitPrizeMax.
             * @member {number} hitPrizeMax
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.hitPrizeMax = 0;

            /**
             * HisOrder periods.
             * @member {string} periods
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.periods = "";

            /**
             * HisOrder buyTime.
             * @member {number|Long} buyTime
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.buyTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * HisOrder uid.
             * @member {number} uid
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.uid = 0;

            /**
             * HisOrder claim.
             * @member {number} claim
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.claim = 0;

            /**
             * HisOrder hitPrize.
             * @member {number} hitPrize
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.hitPrize = 0;

            /**
             * HisOrder balanceAfter.
             * @member {number} balanceAfter
             * @memberof protobuf.response.HisOrder
             * @instance
             */
            HisOrder.prototype.balanceAfter = 0;

            /**
             * Creates a new HisOrder instance using the specified properties.
             * @function create
             * @memberof protobuf.response.HisOrder
             * @static
             * @param {protobuf.response.IHisOrder=} [properties] Properties to set
             * @returns {protobuf.response.HisOrder} HisOrder instance
             */
            HisOrder.create = function create(properties) {
                return new HisOrder(properties);
            };

            /**
             * Encodes the specified HisOrder message. Does not implicitly {@link protobuf.response.HisOrder.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.HisOrder
             * @static
             * @param {protobuf.response.IHisOrder} message HisOrder message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HisOrder.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                if (message.lottery != null && Object.hasOwnProperty.call(message, "lottery"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.lottery);
                if (message.childGameType != null && Object.hasOwnProperty.call(message, "childGameType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.childGameType);
                if (message.D != null && Object.hasOwnProperty.call(message, "D"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.D);
                if (message.universal != null && Object.hasOwnProperty.call(message, "universal"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.universal);
                if (message.buy != null && Object.hasOwnProperty.call(message, "buy"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.buy);
                if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.tickets);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.quantity);
                if (message.unitPrice != null && Object.hasOwnProperty.call(message, "unitPrice"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.unitPrice);
                if (message.cost != null && Object.hasOwnProperty.call(message, "cost"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.cost);
                if (message.hitPrizeMax != null && Object.hasOwnProperty.call(message, "hitPrizeMax"))
                    writer.uint32(/* id 11, wireType 1 =*/89).double(message.hitPrizeMax);
                if (message.periods != null && Object.hasOwnProperty.call(message, "periods"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.periods);
                if (message.buyTime != null && Object.hasOwnProperty.call(message, "buyTime"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int64(message.buyTime);
                if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int32(message.uid);
                if (message.claim != null && Object.hasOwnProperty.call(message, "claim"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int32(message.claim);
                if (message.hitPrize != null && Object.hasOwnProperty.call(message, "hitPrize"))
                    writer.uint32(/* id 16, wireType 1 =*/129).double(message.hitPrize);
                if (message.balanceAfter != null && Object.hasOwnProperty.call(message, "balanceAfter"))
                    writer.uint32(/* id 17, wireType 1 =*/137).double(message.balanceAfter);
                return writer;
            };

            /**
             * Encodes the specified HisOrder message, length delimited. Does not implicitly {@link protobuf.response.HisOrder.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.HisOrder
             * @static
             * @param {protobuf.response.IHisOrder} message HisOrder message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HisOrder.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a HisOrder message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.HisOrder
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.HisOrder} HisOrder
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HisOrder.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.HisOrder();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.int64();
                            break;
                        }
                    case 2: {
                            message.lottery = reader.int32();
                            break;
                        }
                    case 3: {
                            message.childGameType = reader.string();
                            break;
                        }
                    case 4: {
                            message.D = reader.int32();
                            break;
                        }
                    case 5: {
                            message.universal = reader.bool();
                            break;
                        }
                    case 6: {
                            message.buy = reader.string();
                            break;
                        }
                    case 7: {
                            message.tickets = reader.int32();
                            break;
                        }
                    case 8: {
                            message.quantity = reader.int32();
                            break;
                        }
                    case 9: {
                            message.unitPrice = reader.int32();
                            break;
                        }
                    case 10: {
                            message.cost = reader.int64();
                            break;
                        }
                    case 11: {
                            message.hitPrizeMax = reader.double();
                            break;
                        }
                    case 12: {
                            message.periods = reader.string();
                            break;
                        }
                    case 13: {
                            message.buyTime = reader.int64();
                            break;
                        }
                    case 14: {
                            message.uid = reader.int32();
                            break;
                        }
                    case 15: {
                            message.claim = reader.int32();
                            break;
                        }
                    case 16: {
                            message.hitPrize = reader.double();
                            break;
                        }
                    case 17: {
                            message.balanceAfter = reader.double();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a HisOrder message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.HisOrder
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.HisOrder} HisOrder
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HisOrder.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a HisOrder message.
             * @function verify
             * @memberof protobuf.response.HisOrder
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HisOrder.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.lottery != null && message.hasOwnProperty("lottery"))
                    switch (message.lottery) {
                    default:
                        return "lottery: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    if (!$util.isString(message.childGameType))
                        return "childGameType: string expected";
                if (message.D != null && message.hasOwnProperty("D"))
                    if (!$util.isInteger(message.D))
                        return "D: integer expected";
                if (message.universal != null && message.hasOwnProperty("universal"))
                    if (typeof message.universal !== "boolean")
                        return "universal: boolean expected";
                if (message.buy != null && message.hasOwnProperty("buy"))
                    if (!$util.isString(message.buy))
                        return "buy: string expected";
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    if (!$util.isInteger(message.tickets))
                        return "tickets: integer expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (!$util.isInteger(message.quantity))
                        return "quantity: integer expected";
                if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                    if (!$util.isInteger(message.unitPrice))
                        return "unitPrice: integer expected";
                if (message.cost != null && message.hasOwnProperty("cost"))
                    if (!$util.isInteger(message.cost) && !(message.cost && $util.isInteger(message.cost.low) && $util.isInteger(message.cost.high)))
                        return "cost: integer|Long expected";
                if (message.hitPrizeMax != null && message.hasOwnProperty("hitPrizeMax"))
                    if (typeof message.hitPrizeMax !== "number")
                        return "hitPrizeMax: number expected";
                if (message.periods != null && message.hasOwnProperty("periods"))
                    if (!$util.isString(message.periods))
                        return "periods: string expected";
                if (message.buyTime != null && message.hasOwnProperty("buyTime"))
                    if (!$util.isInteger(message.buyTime) && !(message.buyTime && $util.isInteger(message.buyTime.low) && $util.isInteger(message.buyTime.high)))
                        return "buyTime: integer|Long expected";
                if (message.uid != null && message.hasOwnProperty("uid"))
                    if (!$util.isInteger(message.uid))
                        return "uid: integer expected";
                if (message.claim != null && message.hasOwnProperty("claim"))
                    if (!$util.isInteger(message.claim))
                        return "claim: integer expected";
                if (message.hitPrize != null && message.hasOwnProperty("hitPrize"))
                    if (typeof message.hitPrize !== "number")
                        return "hitPrize: number expected";
                if (message.balanceAfter != null && message.hasOwnProperty("balanceAfter"))
                    if (typeof message.balanceAfter !== "number")
                        return "balanceAfter: number expected";
                return null;
            };

            /**
             * Creates a HisOrder message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.HisOrder
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.HisOrder} HisOrder
             */
            HisOrder.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.HisOrder)
                    return object;
                let message = new $root.protobuf.response.HisOrder();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                switch (object.lottery) {
                default:
                    if (typeof object.lottery === "number") {
                        message.lottery = object.lottery;
                        break;
                    }
                    break;
                case "SSC":
                case 0:
                    message.lottery = 0;
                    break;
                case "LHC":
                case 1:
                    message.lottery = 1;
                    break;
                case "XZC":
                case 2:
                    message.lottery = 2;
                    break;
                case "FC3D":
                case 3:
                    message.lottery = 3;
                    break;
                case "TEXAS":
                case 4:
                    message.lottery = 4;
                    break;
                case "SLOTS":
                case 5:
                    message.lottery = 5;
                    break;
                }
                if (object.childGameType != null)
                    message.childGameType = String(object.childGameType);
                if (object.D != null)
                    message.D = object.D | 0;
                if (object.universal != null)
                    message.universal = Boolean(object.universal);
                if (object.buy != null)
                    message.buy = String(object.buy);
                if (object.tickets != null)
                    message.tickets = object.tickets | 0;
                if (object.quantity != null)
                    message.quantity = object.quantity | 0;
                if (object.unitPrice != null)
                    message.unitPrice = object.unitPrice | 0;
                if (object.cost != null)
                    if ($util.Long)
                        (message.cost = $util.Long.fromValue(object.cost)).unsigned = false;
                    else if (typeof object.cost === "string")
                        message.cost = parseInt(object.cost, 10);
                    else if (typeof object.cost === "number")
                        message.cost = object.cost;
                    else if (typeof object.cost === "object")
                        message.cost = new $util.LongBits(object.cost.low >>> 0, object.cost.high >>> 0).toNumber();
                if (object.hitPrizeMax != null)
                    message.hitPrizeMax = Number(object.hitPrizeMax);
                if (object.periods != null)
                    message.periods = String(object.periods);
                if (object.buyTime != null)
                    if ($util.Long)
                        (message.buyTime = $util.Long.fromValue(object.buyTime)).unsigned = false;
                    else if (typeof object.buyTime === "string")
                        message.buyTime = parseInt(object.buyTime, 10);
                    else if (typeof object.buyTime === "number")
                        message.buyTime = object.buyTime;
                    else if (typeof object.buyTime === "object")
                        message.buyTime = new $util.LongBits(object.buyTime.low >>> 0, object.buyTime.high >>> 0).toNumber();
                if (object.uid != null)
                    message.uid = object.uid | 0;
                if (object.claim != null)
                    message.claim = object.claim | 0;
                if (object.hitPrize != null)
                    message.hitPrize = Number(object.hitPrize);
                if (object.balanceAfter != null)
                    message.balanceAfter = Number(object.balanceAfter);
                return message;
            };

            /**
             * Creates a plain object from a HisOrder message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.HisOrder
             * @static
             * @param {protobuf.response.HisOrder} message HisOrder
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HisOrder.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.lottery = options.enums === String ? "SSC" : 0;
                    object.childGameType = "";
                    object.D = 0;
                    object.universal = false;
                    object.buy = "";
                    object.tickets = 0;
                    object.quantity = 0;
                    object.unitPrice = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.cost = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.cost = options.longs === String ? "0" : 0;
                    object.hitPrizeMax = 0;
                    object.periods = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.buyTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.buyTime = options.longs === String ? "0" : 0;
                    object.uid = 0;
                    object.claim = 0;
                    object.hitPrize = 0;
                    object.balanceAfter = 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                if (message.lottery != null && message.hasOwnProperty("lottery"))
                    object.lottery = options.enums === String ? $root.protobuf.common.Lottery[message.lottery] === undefined ? message.lottery : $root.protobuf.common.Lottery[message.lottery] : message.lottery;
                if (message.childGameType != null && message.hasOwnProperty("childGameType"))
                    object.childGameType = message.childGameType;
                if (message.D != null && message.hasOwnProperty("D"))
                    object.D = message.D;
                if (message.universal != null && message.hasOwnProperty("universal"))
                    object.universal = message.universal;
                if (message.buy != null && message.hasOwnProperty("buy"))
                    object.buy = message.buy;
                if (message.tickets != null && message.hasOwnProperty("tickets"))
                    object.tickets = message.tickets;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = message.quantity;
                if (message.unitPrice != null && message.hasOwnProperty("unitPrice"))
                    object.unitPrice = message.unitPrice;
                if (message.cost != null && message.hasOwnProperty("cost"))
                    if (typeof message.cost === "number")
                        object.cost = options.longs === String ? String(message.cost) : message.cost;
                    else
                        object.cost = options.longs === String ? $util.Long.prototype.toString.call(message.cost) : options.longs === Number ? new $util.LongBits(message.cost.low >>> 0, message.cost.high >>> 0).toNumber() : message.cost;
                if (message.hitPrizeMax != null && message.hasOwnProperty("hitPrizeMax"))
                    object.hitPrizeMax = options.json && !isFinite(message.hitPrizeMax) ? String(message.hitPrizeMax) : message.hitPrizeMax;
                if (message.periods != null && message.hasOwnProperty("periods"))
                    object.periods = message.periods;
                if (message.buyTime != null && message.hasOwnProperty("buyTime"))
                    if (typeof message.buyTime === "number")
                        object.buyTime = options.longs === String ? String(message.buyTime) : message.buyTime;
                    else
                        object.buyTime = options.longs === String ? $util.Long.prototype.toString.call(message.buyTime) : options.longs === Number ? new $util.LongBits(message.buyTime.low >>> 0, message.buyTime.high >>> 0).toNumber() : message.buyTime;
                if (message.uid != null && message.hasOwnProperty("uid"))
                    object.uid = message.uid;
                if (message.claim != null && message.hasOwnProperty("claim"))
                    object.claim = message.claim;
                if (message.hitPrize != null && message.hasOwnProperty("hitPrize"))
                    object.hitPrize = options.json && !isFinite(message.hitPrize) ? String(message.hitPrize) : message.hitPrize;
                if (message.balanceAfter != null && message.hasOwnProperty("balanceAfter"))
                    object.balanceAfter = options.json && !isFinite(message.balanceAfter) ? String(message.balanceAfter) : message.balanceAfter;
                return object;
            };

            /**
             * Converts this HisOrder to JSON.
             * @function toJSON
             * @memberof protobuf.response.HisOrder
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HisOrder.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for HisOrder
             * @function getTypeUrl
             * @memberof protobuf.response.HisOrder
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            HisOrder.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.HisOrder";
            };

            return HisOrder;
        })();

        response.BuyAgainResponse = (function() {

            /**
             * Properties of a BuyAgainResponse.
             * @memberof protobuf.response
             * @interface IBuyAgainResponse
             * @property {protobuf.request.ICommonRequest|null} [commonRequest] BuyAgainResponse commonRequest
             */

            /**
             * Constructs a new BuyAgainResponse.
             * @memberof protobuf.response
             * @classdesc Represents a BuyAgainResponse.
             * @implements IBuyAgainResponse
             * @constructor
             * @param {protobuf.response.IBuyAgainResponse=} [properties] Properties to set
             */
            function BuyAgainResponse(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BuyAgainResponse commonRequest.
             * @member {protobuf.request.ICommonRequest|null|undefined} commonRequest
             * @memberof protobuf.response.BuyAgainResponse
             * @instance
             */
            BuyAgainResponse.prototype.commonRequest = null;

            /**
             * Creates a new BuyAgainResponse instance using the specified properties.
             * @function create
             * @memberof protobuf.response.BuyAgainResponse
             * @static
             * @param {protobuf.response.IBuyAgainResponse=} [properties] Properties to set
             * @returns {protobuf.response.BuyAgainResponse} BuyAgainResponse instance
             */
            BuyAgainResponse.create = function create(properties) {
                return new BuyAgainResponse(properties);
            };

            /**
             * Encodes the specified BuyAgainResponse message. Does not implicitly {@link protobuf.response.BuyAgainResponse.verify|verify} messages.
             * @function encode
             * @memberof protobuf.response.BuyAgainResponse
             * @static
             * @param {protobuf.response.IBuyAgainResponse} message BuyAgainResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyAgainResponse.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.commonRequest != null && Object.hasOwnProperty.call(message, "commonRequest"))
                    $root.protobuf.request.CommonRequest.encode(message.commonRequest, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified BuyAgainResponse message, length delimited. Does not implicitly {@link protobuf.response.BuyAgainResponse.verify|verify} messages.
             * @function encodeDelimited
             * @memberof protobuf.response.BuyAgainResponse
             * @static
             * @param {protobuf.response.IBuyAgainResponse} message BuyAgainResponse message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuyAgainResponse.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuyAgainResponse message from the specified reader or buffer.
             * @function decode
             * @memberof protobuf.response.BuyAgainResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {protobuf.response.BuyAgainResponse} BuyAgainResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyAgainResponse.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.protobuf.response.BuyAgainResponse();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.commonRequest = $root.protobuf.request.CommonRequest.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuyAgainResponse message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof protobuf.response.BuyAgainResponse
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {protobuf.response.BuyAgainResponse} BuyAgainResponse
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuyAgainResponse.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuyAgainResponse message.
             * @function verify
             * @memberof protobuf.response.BuyAgainResponse
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuyAgainResponse.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.commonRequest != null && message.hasOwnProperty("commonRequest")) {
                    let error = $root.protobuf.request.CommonRequest.verify(message.commonRequest);
                    if (error)
                        return "commonRequest." + error;
                }
                return null;
            };

            /**
             * Creates a BuyAgainResponse message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof protobuf.response.BuyAgainResponse
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {protobuf.response.BuyAgainResponse} BuyAgainResponse
             */
            BuyAgainResponse.fromObject = function fromObject(object) {
                if (object instanceof $root.protobuf.response.BuyAgainResponse)
                    return object;
                let message = new $root.protobuf.response.BuyAgainResponse();
                if (object.commonRequest != null) {
                    if (typeof object.commonRequest !== "object")
                        throw TypeError(".protobuf.response.BuyAgainResponse.commonRequest: object expected");
                    message.commonRequest = $root.protobuf.request.CommonRequest.fromObject(object.commonRequest);
                }
                return message;
            };

            /**
             * Creates a plain object from a BuyAgainResponse message. Also converts values to other types if specified.
             * @function toObject
             * @memberof protobuf.response.BuyAgainResponse
             * @static
             * @param {protobuf.response.BuyAgainResponse} message BuyAgainResponse
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuyAgainResponse.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.commonRequest = null;
                if (message.commonRequest != null && message.hasOwnProperty("commonRequest"))
                    object.commonRequest = $root.protobuf.request.CommonRequest.toObject(message.commonRequest, options);
                return object;
            };

            /**
             * Converts this BuyAgainResponse to JSON.
             * @function toJSON
             * @memberof protobuf.response.BuyAgainResponse
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuyAgainResponse.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BuyAgainResponse
             * @function getTypeUrl
             * @memberof protobuf.response.BuyAgainResponse
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BuyAgainResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/protobuf.response.BuyAgainResponse";
            };

            return BuyAgainResponse;
        })();

        return response;
    })();

    return protobuf;
})();

export { $root as default };
