<template>
	<div class="page">
		<div class="container-box mx-auto aspect-auto h-screen 2xl:h-auto 2xl:aspect-[16/9]">
			<headertop></headertop>
			<div class="main-box">
				<!-- MD以上显示左侧菜单按钮 -->
				<div class="menu-box">
					<div class="game-time-box" v-if="gameStatus == 'RUNNING'">
						{{timeNum}}
					</div>
					<div class="game-time-box" v-else>
						{{gameStatu}}
					</div>
					<div class="username-box">
						{{userInfo.nickName}}
					</div>
					<div class="userMoney-box">
						<div class="unit-box">$</div>
						<div class="money-box">{{userInfo.balanceAmount}}</div>
					</div>
					<div class="game-menu">
						<div class="game-menu-item" :class="gameType == 0 ? 'active' : '' " @click='toggleGameType(0)'>大小</div>
						<div class="game-menu-item" :class="gameType == 1 ? 'active' : '' " @click='toggleGameType(1)'>单双</div>
						<div class="game-menu-item" :class="gameType == 2 ? 'active' : '' " @click='toggleGameType(2)'>和值</div>
						<div class="game-menu-item" :class="gameType == 3 ? 'active' : '' " @click='toggleGameType(3)'>星彩</div>
						<div class="game-menu-item" :class="gameType == 4 ? 'active' : '' " @click='toggleGameType(4)'>包号</div>
					</div>
					
					<div class="toggle-live">
						<div class="toggle-live-item" :class="menuType == 0 ? 'active' : '' " @click="menuType = 0">直播</div>
						<div class="toggle-live-item" :class="menuType == 1 ? 'active' : '' " @click="menuType = 1">历史</div>
					</div>
				</div>
				<!-- 正常游戏区域 -->
				<div class="center-box" v-if="menuType != 2">
					<div class="live-box" v-if="menuType == 0">
						<!-- <sscHistory1Vue></sscHistory1Vue> -->
					</div>
					<div class="history-box" v-if="menuType == 1">
						<sscHistory1Vue v-if="gameType == 0 || gameType == 1"></sscHistory1Vue>
						<sscHistory2Vue v-if="gameType == 2"></sscHistory2Vue>
						<sscHistory3Vue v-if="gameType == 3 || gameType == 4 "></sscHistory3Vue>
					</div>
					
					<div class="action-box-pc mt-[20px] hidden 2xl:flex">
						<!-- 通选 复选 -->
						<div class="check-box" v-if="gameType == 0 || gameType == 1 || gameType == 3">
							<div class="check-row w-full">
								<div class="check-item w-row mb-[5px]" :class="universal ? 'active' : '' " @click="toggleAllBet">
									<div class="radio"></div>
									<div>通选</div>
								</div>
							</div>
							<div class="check-row">
								<div class="check-item"   @click="toggleGameIndex(0)" :class="gameIndex == 0 || (universal && gameIndex > 0) ? 'active' : '' ">
									<div class="radio"></div>
									<div>1D</div>
								</div>
								<div class="check-item"   @click="toggleGameIndex(1)" :class="gameIndex == 1 || (universal && gameIndex > 1) ? 'active' : '' ">
									<div class="radio"></div>
									<div>2D</div>
								</div>
								<div class="check-item"   @click="toggleGameIndex(2)" :class="gameIndex == 2 || (universal && gameIndex > 2) ? 'active' : '' ">
									<div class="radio"></div>
									<div>3D</div>
								</div>
								<div class="check-item"   @click="toggleGameIndex(3)" :class="gameIndex == 3 ||  (universal && gameIndex > 3) ? 'active' : '' ">
									<div class="radio"></div>
									<div>4D</div>
								</div>
								<div class="check-item"   @click="toggleGameIndex(4)" :class="gameIndex == 4 ||  (universal && gameIndex > 4) ? 'active' : '' ">
									<div class="radio"></div>
									<div>5D</div>
								</div>
							</div>
						</div>
						<div class="check-box" v-if="gameType == 2">
							<div class="check-row">
								<div class="check-item"   @click="toggleGameIndex(1)" :class="gameIndex == 1 ? 'active' : '' ">
									<div class="radio"></div>
									<div>1D-2D</div>
								</div>
								<div class="check-item"   @click="toggleGameIndex(2)" :class="gameIndex == 2 ? 'active' : '' ">
									<div class="radio"></div>
									<div>1D-3D</div>
								</div>
							</div>
						</div>
						<div class="check-box" v-if="gameType == 4">
							<div class="check-row">
								<div class="check-item" @click="toggleGameIndex(2)" :class="gameIndex == 2 ? 'active' : '' ">
									<div class="radio"></div>
									<div>3D</div>
								</div>
								<div class="check-item" @click="toggleGameIndex(3)" :class="gameIndex == 3 ? 'active' : '' ">
									<div class="radio"></div>
									<div>4D</div>
								</div>
								<div class="check-item" @click="toggleGameIndex(4)" :class="gameIndex == 4 ? 'active' : '' ">
									<div class="radio"></div>
									<div>5D</div>
								</div>
							</div>
						</div>
						<!-- 投注预选区域 -->
						<div class="perselect-box 2xl:flex">
							<div class="perselect-item flex justify-center items-center text-[#ffffff]">
								<div class="">QUANTITY:</div>
								<div class="text-center text-[#BCFF2F] ml-[5px]">{{calculateRes.quantity ? calculateRes.quantity : 0}}</div>
							</div>
							<div class="perselect-item flex justify-center items-center text-[#ffffff]">
								<div class="">TICKET(S):</div>
								<div class="text-center text-[#BCFF2F] ml-[5px]">{{calculateRes.tickets ? calculateRes.tickets : 0}}</div>
							</div>
							<div class="perselect-item flex justify-center items-center text-[#ffffff]">
								<div class="">PRICE:</div>
								<div class="text-center text-[#BCFF2F] ml-[5px]">${{calculateRes.price ? calculateRes.price : 0}}</div>
							</div>
						</div>
						<div class="chip-box-pc 2xl:flex">
							<img class="chip-icon w-[37px] h-[25px]" src="@/assets/icons/Vector.png" alt="" />
							<div class="chip-item" v-for="(item,index) in 7" @click="chipIndex = index" :class="chipIndex == index ? 'active' : '' ">
								{{chipData[index]}}
							</div>
						</div>
					</div>
					<div class="action-box">
						<div class="mb-2 h-[40px] bg-[#000000] mx-1 text-[#ffffff] flex justify-center items-center 2xl:hidden" v-if="gameStatus == 'RUNNING'">
							{{timeNum}}
						</div>
						<div class="bg-green h-0 mx-1 2xl:hidden" :class="gameStatus != 'RUNNING' ? 'text-[60px] mb-2 h-[200px] flex justify-center items-center' : ''">
							{{gameStatus}}
						</div>
						<div class="toggle-game">
							<div class="game-item" :class="gameType == 0 ? 'active' : '' " @click='toggleGameType(0)'>大小</div>
							<div class="game-item" :class="gameType == 1 ? 'active' : '' "  @click='toggleGameType(1)'>单双</div>
							<div class="game-item" :class="gameType == 2 ? 'active' : '' " @click='toggleGameType(2)'>和值</div>
							<div class="game-item" :class="gameType == 3 ? 'active' : '' " @click='toggleGameType(3)'>星彩</div>
							<div class="game-item" :class="gameType == 4 ? 'active' : '' " @click='toggleGameType(4)'>包号</div>
						</div>
						<!-- 单双大小 1D-5D -->
						<div class="check-box-md"  v-if="gameType == 0 || gameType == 1 || gameType == 3">
							<div class="check-row">
								<div v-if="gameIndex > 0" class="check-item" :class="universal ? 'active' : ''" @click="toggleAllBet">
									<div class="radio"></div>
									<div>通选</div>
								</div>
								<div @click="toggleGameIndex(0)" class="check-item" :class="(gameIndex == 0) || (universal && gameIndex > 0) ? 'active' : '' ">
									<div class="radio"></div>
									<div>1D</div>
								</div>
								<div @click="toggleGameIndex(1)" class="check-item" :class="(gameIndex == 1) || (universal && gameIndex > 1) ? 'active' : '' ">
									<div class="radio"></div>
									<div>2D</div>
								</div>
								<div @click="toggleGameIndex(2)" class="check-item" :class="(gameIndex == 2) || (universal && gameIndex > 2) ? 'active' : '' ">
									<div class="radio"></div>
									<div>3D</div>
								</div>
								<div @click="toggleGameIndex(3)" class="check-item" :class="(gameIndex == 3) || (universal && gameIndex > 3) ? 'active' : '' ">
									<div class="radio"></div>
									<div>4D</div>
								</div>
								<div @click="toggleGameIndex(4)" class="check-item" :class="(gameIndex == 4) || (universal && gameIndex > 4) ? 'active' : '' ">
									<div class="radio"></div>
									<div>5D</div>
								</div>
							</div>
						</div>
						<div class="check-box-md"  v-if="gameType == 2">
							<div class="check-row">
								<div @click="toggleGameIndex(1)" class="check-item" :class="gameIndex == 1 ? 'active' : '' ">
									<div class="radio"></div>
									<div>1D-2D</div>
								</div>
								<div @click="toggleGameIndex(2)" class="check-item" :class="gameIndex == 2 ? 'active' : '' ">
									<div class="radio"></div>
									<div>1D-3D</div>
								</div>
							</div>
						</div>
						<div class="check-box-md"  v-if="gameType == 4">
							<div class="check-row">
								<div @click="toggleGameIndex(2)" class="check-item" :class="(gameIndex == 2) || (universal && gameIndex > 2) ? 'active' : '' ">
									<div class="radio"></div>
									<div>3D</div>
								</div>
								<div @click="toggleGameIndex(3)" class="check-item" :class="(gameIndex == 3) || (universal && gameIndex > 3) ? 'active' : '' ">
									<div class="radio"></div>
									<div>4D</div>
								</div>
								<div @click="toggleGameIndex(4)" class="check-item" :class="(gameIndex == 4) || (universal && gameIndex > 4) ? 'active' : '' ">
									<div class="radio"></div>
									<div>5D</div>
								</div>
							</div>
						</div>
						<!-- 赔率 -->
						<div class="game-detial 2xl:hidden">
							<div class="odds-box">
								<!-- <span class="mr-3">$2/TICKET</span>
								<span>TICKETS LIMT:20K</span> -->
								<span>ODDS:{{oddsData}}</span>
							</div>
							<div class="money-box">
								${{userInfo.balanceAmount}}
								<span class="icon size-5"></span>
							</div>
						</div>
						<!-- PC版 -->
						<!-- <div class="game-detial hidden 2xl:flex">
							<div class="odds-box">
								<span class="mr-3">$2/TICKET</span>
							</div>
							<div class="odds-box">
								<span>TICKETS LIMT:20K</span>
							</div>
						</div> -->
						<!-- 操作 -->
						<div class="operate-box-pc">
							<div class="operate-item-box" @click="reset()">
								RESET
							</div>
							<div class="operate-item-box" @click="betUnAdd()">
								SET-
							</div>
							<div class="operate-item-box" @click="betAdd()">
								SET+
							</div>
							<div class="operate-item-box">
								CONFI
							</div>
						</div>
						<!-- <div class="chip-box-md">
							<div class="chip-icon w-[37px] h-[25px]" style="border: 1px solid red;"></div>
							<div class="chip-item">1</div>
							<div class="chip-item">10</div>
							<div class="chip-item">50</div>
							<div class="chip-item">100</div>
							<div class="chip-item">200</div>
							<div class="chip-item">500</div>
						</div> -->
						<div class="clear-float"></div>
						
						<!-- 菜单按钮区域 -->
						<div v-if="gameType == 0" class="flex flex-col 2xl:flex-row 2xl:justify-center">
							<div class="action-row-2 mb-4 2xl:mb-0 2xl:mx-[10px]" :class="gameIndex == 0 ? '2xl:mt-[40px]' : '2xl:mt-[20px]' " v-for="(item,index) in (gameIndex+1)" >
								<div v-if="gameIndex >= 1" class="action-item-label" :class="option[index] ? (option[index] == 'S' ? 'bg-blue' : 'bg-red') : '' ">
									{{item}}D
								</div>
								<div class="flex-1 flex flex-row flex-wrap justify-evenly items-center 2xl:w-full 2xl:flex 2xl:flex-row 2xl:justify-between">
									<div class="action-item-box" :class="option[index] == 'S' ? 'bg-blue' : '' " @click="selectOption(index,'S')">
										小
									</div>
									<div class="action-item-box" :class="option[index] == 'B' ? 'bg-red' : '' " @click="selectOption(index,'B')">
										大
									</div>
								</div>
								
							</div>
						</div>
						<div v-if="gameType == 1" class="flex flex-col 2xl:flex-row 2xl:justify-center">
							<div class="action-row-2 mb-4 2xl:mb-0 2xl:mx-[10px]" :class="gameIndex == 0 ? '2xl:mt-[40px]' : '2xl:mt-[20px]' " v-for="(item,index) in (gameIndex+1)" >
								<div v-if="gameIndex >= 1" class="action-item-label" :class="option[index] ? (option[index] == 'O' ? 'bg-blue' : 'bg-red') : '' ">
									{{item}}D
								</div>
								<div class="flex-1 flex flex-row flex-wrap justify-evenly items-center 2xl:w-full 2xl:flex 2xl:flex-row 2xl:justify-between">
									<div class="action-item-box" :class="option[index] == 'O' ? 'bg-blue' : '' " @click="selectOption(index,'O')">
										单
									</div>
									<div class="action-item-box" :class="option[index] == 'E' ? 'bg-red' : '' " @click="selectOption(index,'E')">
										双
									</div>
								</div>
								
							</div>
						</div>
						<div v-if="gameType == 2" class="flex flex-col 2xl:flex-row 2xl:mt-[60px]">
							<div class="action-row-17" v-if="gameIndex == 1">
								<div class="action-item-box" @click="selectSumOption(index)" :class="sumOption.includes(index) ? 'active' : '' " v-for="(item,index) in 19" >
									{{index}}
								</div>
							</div>
							
							<div class="action-row-28" v-if="gameIndex == 2">
								<div class="action-item-box" @click="selectSumOption(index)" :class="sumOption.includes(index) ? 'active' : '' " v-for="(item,index) in 28" >
									{{index}}
								</div>
							</div>
							
						</div>
						
						<div v-if="gameType == 3" class="flex flex-col 2xl:flex-row">
							<div v-for="(item,index) in (gameIndex+1)" class="flex flex-nowrap items-center my-2 2xl:flex-wrap 2xl:flex-1 2xl:mx-[5px]">
								<div v-if="gameIndex > 0" class="action-item-label h-[90px] mx-2 2xl:h-[40px] 2xl:mx-0" :class="getStartClass(startOption[index])">
									{{item}}D
								</div>
								<div class="action-row-10">
									<div class="action-item-box" :class="startOption[index].includes(indexs) ? (indexs <=4 ? 'bg-blue' : 'bg-red') : '' " v-for="(items,indexs) in 10" @click="selectStartOption(index,indexs)">
										{{indexs}}
									</div>
								</div>
							</div>
						</div>
						
						<div v-if="gameType == 4" class="flex flex-col 2xl:flex-row 2xl:mt-[60px]">
							<div class="action-row-10-separate">
								<div @click="selectPackingOption(index)" class="action-item-box" :class="packingOption.includes(index) ? (index <=4 ? 'bg-blue' : 'bg-red') : '' "v-for="(item,index) in 10">
									{{index}}
								</div>
							</div>
						</div>
						
						
						
						<!-- 投注数量 -->
						<div class="chip-box mt-[10px]">
							<img class="chip-icon w-[25px] h-[17px]" src="@/assets/icons/Vector.png" alt="" />
							<div class="chip-item" @click="chipIndex = index" :class="chipIndex == index ? 'active' : '' " v-for="(item,index) in 8">{{chipData[index]}}</div>
						</div>
						<div class="clear-float"></div>
						<!--下注按钮等-->
						<div class="operate-box">
							<div class="operate-item-box" @click="reset()">
								RESET
							</div>
							<div class="operate-item-box" @click="betUnAdd()">
								SET-
							</div>
							<div class="operate-item-box" @click="betAdd()">
								SET+
							</div>
							<div class="operate-item-box" @click="betData()">
								CONFIRM
							</div>
						</div>
						<!-- 投注预选区域 -->
						<div class="perselect-box xl:flex 2xl:hidden">
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1">QUANTITY:</div>
								<div class="flex-1 text-center text-[#BCFF2F]">{{calculateRes.quantity}}</div>
							</div>
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1">TICKET(S):</div>
								<div class="flex-1 text-center text-[#BCFF2F]">{{calculateRes.tickets}}</div>
							</div>
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1">PRICE:</div>
								<div class="flex-1 text-center text-[#BCFF2F]">${{calculateRes.price}}</div>
							</div>
						</div>
						<div class="perselect-box xl:flex 2xl:hidden">
							<div class="flex-1 flex justify-center items-center text-[#ffffff]">
								<div class="ml-4 mr-1 ">HIT PRIZE:</div>
								<div class="flex-1 text-center text-[#BCFF2F]">${{calculateRes.hitPrizeMax ? calculateRes.hitPrizeMax : 0}}</div>
							</div>
						</div>
						
						<!-- <div class="bet-all-box md:hidden">
							<div class="bet-all-title">CURRENT PURCHASE RECORD</div>
							<div class="bet-box">
								<div class="bet-item" v-for="item in 20">
									{{item}}
								</div>
							</div>
						</div> -->
					</div>
										
					<!-- <div class="bet-box">
						预选返奖区域
					</div> -->
				</div>
				<div class="bet-box md:hidden" v-if="menuType == 2">
					<div class="buy-history mt-[10px]">
						<div class="buy-history-title">已购彩票</div>
						<div class="buy-box">
							<div class="buy-item" v-for="item in 10">
								<div class="buy-item-content-box">
									<div>5START KUAICAI 1D-5D</div>
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title-group">
									<div class="buy-item-title-group-item">1</div>
									<div class="buy-item-title-group-item">$100</div>
									<div class="buy-item-title-group-item">$999</div>
								</div>
							</div>
							
							<div class="buy-item">
								<div class="buy-item-title">购彩内容</div>
								<div class="buy-item-name-box">
									<div class="buy-item-name-item">购彩总数</div>
									<div class="buy-item-name-item">总价</div>
									<div class="buy-item-name-item">奖金最大</div>
								</div>
								<div class="buy-item-content-box">
									<div>5START KUAICAI 1D-5D</div>
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title">中奖星座</div>
								<div class="buy-item-content-box">
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title">中奖星座</div>
								<div class="buy-item-content-box">
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title-group">
									<div class="buy-item-title-group-item">购彩总数</div>
									<div class="buy-item-title-group-item">总价</div>
								</div>
								<div class="buy-item-content-group">
									<div class="buy-item-content-group-item">100</div>
									<div class="buy-item-content-group-item">$999</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<!-- 历史记录区域 -->
				<div class="right-box">
					<div class="bet-all-box">
						<div class="bet-all-title">CURRENT PURCHASE RECORD</div>
						<div class="bet-box">
							<div class="bet-item" v-for="item in 20">
								{{item}}
							</div>
						</div>
					</div>
					<div class="buy-history">
						<div class="buy-history-title">已购彩票</div>
						<div class="buy-box">
							<div class="buy-item" v-for="item in 10">
								<div class="buy-item-content-box">
									<div>5START KUAICAI 1D-5D</div>
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title-group">
									<div class="buy-item-title-group-item">1</div>
									<div class="buy-item-title-group-item">$100</div>
									<div class="buy-item-title-group-item">$999</div>
								</div>
							</div>
							
							<div class="buy-item">
								<div class="buy-item-title">购彩内容</div>
								<div class="buy-item-name-box">
									<div class="buy-item-name-item">购彩总数</div>
									<div class="buy-item-name-item">总价</div>
									<div class="buy-item-name-item">奖金最大</div>
								</div>
								<div class="buy-item-content-box">
									<div>5START KUAICAI 1D-5D</div>
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title">中奖星座</div>
								<div class="buy-item-content-box">
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title">中奖星座</div>
								<div class="buy-item-content-box">
									<div>(1,2,3,4,5,5,6,7)</div>
								</div>
								<div class="buy-item-title-group">
									<div class="buy-item-title-group-item">购彩总数</div>
									<div class="buy-item-title-group-item">总价</div>
								</div>
								<div class="buy-item-content-group">
									<div class="buy-item-content-group-item">100</div>
									<div class="buy-item-content-group-item">$999</div>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="bet-box">
						预选返奖区域
					</div> -->
				</div>
				<div class="footer-slot">
				</div>
			</div>
			<div class="footer md:hidden">
				<div class="footer-item" :class="menuType == 0 ? 'selected' : '' " @click="menuType = 0">
					<div class="img-icon"></div>
					<div>直播</div>
				</div>
				<div class="footer-item" :class="menuType == 1 ? 'selected' : '' " @click="menuType = 1">
					<div class="img-icon"></div>
					<div>历史记录</div>
				</div>
				<div class="footer-item" :class="menuType == 2 ? 'selected' : '' " @click="menuType = 2">
					<div class="img-icon"></div>
					<div>投注记录</div>
				</div>
			</div>
			<div class="footer hidden md:flex 2xl:hidden">
				<div class="footer-item" :class="menuType == 0 ? 'selected' : '' " @click="menuType = 0">
					<div class="img-icon"></div>
					<div>直播</div>
				</div>
				<div class="footer-item" :class="menuType == 1 ? 'selected' : '' " @click="menuType = 1">
					<div class="img-icon"></div>
					<div>历史记录</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import sscHistory1Vue from '@/components/sscHistory1.vue'
	import sscHistory2Vue from '@/components/sscHistory2.vue'
	import sscHistory3Vue from '@/components/sscHistory3.vue'
		
	export default{
		components: {
			sscHistory1Vue,
			sscHistory2Vue,
			sscHistory3Vue,
		},
		data(){
			return {
				menuType:0,
				gameType:0,
				gameIndex:0,
				menuList: [
					{
						name: 'Help',
						icon: 'Help',
					},
					{
						name: 'region',
						icon: 'region',
					},
					{
						name: 'Volume',
						icon: 'Volume',
					},
					{
						name: 'Setting',
						icon: 'Setting',
					},
				],
				userInfo:{},
				option:['','','','',''],
				sumOption:[],
				startOption:[[],[],[],[],[]],
				packingOption:[],
				odds:{},
				oddsData:0,
				chipData:[1,10,50,100,200,500,1000,2000],
				chipIndex:0,
				bet:1,
				universal:false,
				quantity:0,
				calculateRes:{},
				timeNum:60,
				gameStatus:"RUNNING",
			}
		},
		mounted() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
			this.$ws.offAll();
			
			this.$ws.on(0,this.openGame);
			this.$ws.on(3,this.initGameInfo);
			this.$ws.on(7,this.updateUserInfo);
			this.$ws.on(9,this.getOdds);
			this.$ws.on(11,this.gameUpdate);
			this.$ws.on(100,this.calculateResult);
			this.$ws.on(101,this.payResult);
			let data = {
				lotteryEnum:'SSC',
				status:1,
			}
			this.$ws.send(data)
		},
		destroyed() {
			this.$ws.off(0,this.openGame);
			this.$ws.off(3,this.initGameInfo);
			this.$ws.off(7,this.updateUserInfo);
			this.$ws.off(9,this.getOdds);
			this.$ws.off(11,this.gameUpdate);
			this.$ws.off(100,this.calculateResult);
			this.$ws.off(101,this.payResult);
		},
		methods:{
			getStartClass(numbers) {
			  const has0to4 = numbers.some(num => num >= 0 && num <= 4);
			  const has5to9 = numbers.some(num => num >= 5 && num <= 9);
		
			  if (has0to4 && has5to9) {
				return 'bg-green'; // 同时包含0-4和5-9
			  } else if (has0to4) {
				return 'bg-blue'; // 只包含0-4
			  } else if (has5to9) {
				return 'bg-red'; // 只包含5-9
			  } else {
				return ''; // 不包含0-4或者5-9
			  }
			},
			openGame(data){
				console.log("开奖数据",data);
			},
			initGameInfo(data){
				console.log("游戏数据",data);
				console.log("游戏状态",data.data.status);
				console.log("游戏时间",data.data.duration);
				this.timeNum = data.data.duration;
				this.gameStatus = data.data.status;
			},
			calculateResult(data){
				let res = this.$buff.response.CalculateResponse.decode(data.payload);
				console.log(res);
				this.calculateRes = res;
			},
			payResult(data){
				console.log("结果",data);
				if(data.code != 0){
					alert("购买失败")
				}else{
					alert("购买成功")
				}
			},
			updateCalculate(){
				let gameType = this.gameType == 0 ? 'SMALL_BIG' : this.gameType == 1 ? 'SINGLE_DOUBLE' : this.gameType == 2 ? 'SUM' : this.gameType == 3 ? 'STARS' : 'PACKING';
				let buyData;
				if(this.gameType == 0 || this.gameType == 1){
					buyData = this.option.join(',');
				}else if(this.gameType == 2){
					buyData = this.sumOption.join(',');
				}else if(this.gameType == 3){
					buyData = this.startOption.map(item => item.join('')).join(',');
				}else if(this.gameType == 4){
					buyData = this.packingOption.join(',');
				}
				let data = this.$tool.createSSCBetData({
					key:'CALCULATE',
					tickets:this.bet,
					D:this.gameIndex+1,
					buy:buyData,
					universal:this.universal,
					gameType:gameType
				});
				console.log(this.option.join(','));
				this.$ws.send(data);
			},
			betData(){
				let gameType = this.gameType == 0 ? 'SMALL_BIG' : this.gameType == 1 ? 'SINGLE_DOUBLE' : this.gameType == 2 ? 'SUM' : this.gameType == 3 ? 'STARS' : 'PACKING';
				let buyData;
				if(this.gameType == 0 || this.gameType == 1){
					buyData = this.option.join(',');
				}else if(this.gameType == 2){
					buyData = this.sumOption.join(',');
				}else if(this.gameType == 3){
					buyData = this.startOption.map(item => item.join('')).join(',');
				}else if(this.gameType == 4){
					buyData = this.packingOption.join(',');
				}
				let data = this.$tool.createSSCBetData({
					key:'BUY',
					tickets:this.bet,
					D:this.gameIndex+1,
					buy:buyData,
					universal:this.universal,
					gameType:gameType
				});
				this.$ws.send(data);
			},
			updateUserInfo(data){
				this.userInfo = data.data;
				localStorage.setItem('userInfo',JSON.stringify(data.data));
			},
			toggleGameType(index){
				this.gameType = index;
				this.reset();
			},
			toggleGameIndex(index){
				this.gameIndex = index;
				this.option = ['','','','',''];
				this.sumOption = [];
				this.startOption = [[],[],[],[],[]];
				this.packingOption = [];
			},
			selectStartOption(index,num){
				let itemArr = this.startOption[index];
				const idx = itemArr.indexOf(num);
				if (idx === -1) {
					// 数字不在数组中，插入它
					itemArr.push(num);
				} else {
					// 数字在数组中，删除它
					itemArr.splice(idx, 1);
				};
				this.startOption[index] = itemArr;
				
				this.updateCalculate();
			},
			selectOption(index,key){
				this.option[index] = this.option[index] == key ? false : key;
				this.updateCalculate();
			},
			selectSumOption(num){
				const index = this.sumOption.indexOf(num);
				if (index === -1) {
					// 数字不在数组中，插入它
					this.sumOption.push(num);
				} else {
					// 数字在数组中，删除它
					this.sumOption.splice(index, 1);
				}
				this.updateCalculate();
			},
			selectPackingOption(num){
				const index = this.packingOption.indexOf(num);
				if (index === -1) {
					// 数字不在数组中，插入它
					this.packingOption.push(num);
				} else {
					// 数字在数组中，删除它
					this.packingOption.splice(index, 1);
				}
				this.updateCalculate();
			},
			gameUpdate(data){
				console.log("游戏更新-",data);
				this.timeNum-=1;
			},
			getOdds(data){
				console.log("获取赔率-",data);
				this.odds = data.data;
				this.updateOdds();
			},
			updateOdds(){
				let odds = this.gameType == 0 ? this.odds['1'] : this.gameType == 1 ? this.odds['1'] : this.odds['1'];
				if(this.gameIndex == 0){
					this.oddsData = odds['oneAmount'];
				}else if(this.gameIndex == 1){
					this.oddsData = odds['twoAmount'];
				}else if(this.gameIndex == 2){
					this.oddsData = odds['threeAmount'];
				}else if(this.gameIndex == 3){
					this.oddsData = odds['fourAmount'];
				}else if(this.gameIndex == 4){
					this.oddsData = odds['fiveAmount'];
				}
			},
			betAdd(){
				this.bet += this.chipData[this.chipIndex];
				// this.computerBetNum();
				this.updateCalculate();
			},
			betUnAdd(){
				this.bet -= this.chipData[this.chipIndex];
				this.bet = this.bet < 1 ? 1 : this.bet;
				// this.computerBetNum();
				this.updateCalculate();
			},
			toggleAllBet(){
				this.universal = !this.universal;
				// this.computerBetNum();
				this.updateCalculate();
			},
			reset(){
				this.option = ['','','','',''];
				this.sumOption = [];
				this.startOption = [[],[],[],[],[]];
				this.packingOption = [];
				this.bet = 1;
				this.quantity = 0;
				this.universal = false;
				if(this.gameType == 2){
					this.gameIndex =1;
				}else if(this.gameType == 4){
					this.gameIndex = 2;
				}else{
					this.gameIndex = 0;
				}
				this.updateCalculate();
			}
		},
		
	}
</script>

<style lang="scss">
	.center-box{
		@apply flex-1 flex flex-col md:pb-0 md:w-[65%] 2xl:w-6/12;
		.live-box{
			background: #BCFF2F;;
			@apply flex w-full aspect-[2/1] shrink-0 overflow-auto;
		}
	}
	.history-box{
		height: calc(100vh - 40px - 66px);
		@apply flex w-full overflow-hidden scrollbar-none shrink-0 2xl:aspect-[2/1] 2xl:h-[inherit];
	}
	.user-bet-history{
		background: #2CABE7;;
		@apply flex w-full h-full;
	}
	.main-box{
		background: theme('colors.bgColor3');
		flex: 1;
		@apply w-full h-auto md:flex flex-row md:justify-between md:overflow-hidden px-0 md:px-0;
		.action-box{
			@apply w-full mt-[10px] px-[5px] md:pb-[80px] 2xl:px-0 overflow-y-scroll scrollbar-none 2xl:overflow-hidden;
			.toggle-game{
				@apply w-full flex flex-row flex-nowrap justify-around 2xl:hidden;
				overflow-x: auto;
				.game-item{
					background: #424242;
					color:#fff;
					@apply flex shrink-0 justify-center flex-1 mx-[5px] h-[40px] items-center text-[12px];
					&.active{
						background: #BCFF2F;
						color: #000000;
					}
				}
			}
			.chip-box-md{
				@apply w-full hidden md:w-[85%] lg:w-[50%] flex flex-row justify-between items-center px-2 float-right mt-4;
				.chip-item{
					background: #000;
					color: #929292;
					@apply size-[30px] md:size-[40px] rounded-full text-[12px] flex items-center justify-center;
				}
			}
			.clear-float{
				@apply hidden md:block clear-both;
			}
		}
		
	}
</style>